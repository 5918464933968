import React, { MutableRefObject, useEffect } from 'react'
import './OptionalMessagePreview.scss'
import ChatMessage from '../ChatMessages/ChatMessage'
import TextareaAutosize from 'react-autosize-textarea'
import Avatar from '../Avatar'
import SendViaEmail from '@web/common/img/SendViaEmail.svg'
import InfoIcon from '@web/common/img/InfoIcon.svg'
import { Connection, Message } from '@web/_types'
import { useTranslation } from 'react-i18next'
import { EMAIL_STATUS, I18N, MESSAGE_TYPES } from '@web/_constants'
import { classNames } from '@web/_utils'
import { isRep } from '@web/_guards'
import _ from 'lodash'

interface IOptionalMessagePreviewProps {
  message: Message
  recipient: Connection | null
  email: string | null
  emailStatus: string | null
  clearRecipientData: () => void
  placeholder?: string
  comment: boolean | null
  useViewTextBanner?: boolean
  forwarded?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const OptionalMessagePreview = React.forwardRef<
  HTMLTextAreaElement,
  IOptionalMessagePreviewProps
>(
  (
    {
      recipient,
      comment,
      clearRecipientData,
      message,
      email,
      emailStatus,
      placeholder,
      onChange,
      useViewTextBanner = true,
      forwarded = false,
    },
    ref
  ) => {
    const { t } = useTranslation(I18N.namespaces.web)

    useEffect(() => {
      const optMsgRef = ref as MutableRefObject<HTMLTextAreaElement>
      optMsgRef.current.focus()
    }, [])

    const cx = {
      'no-border': !forwarded,
      'no-click': _.includes(
        [MESSAGE_TYPES.shareRep, MESSAGE_TYPES.shareHCP],
        message.type
      ),
    }
    const messagePreviewClasses = classNames(cx)

    return (
      <div id="optional-message-preview">
        {!!email && emailStatus === EMAIL_STATUS.valid && (
          <div id="optional-message-banner">
            <img src={InfoIcon} />
            {useViewTextBanner
              ? t('recipientMustAcceptConnectionView')
              : t('recipientMustAcceptConnectionReply')}
          </div>
        )}

        <div id="optional-message-to">
          <div id="to">{t('to')}</div>

          {!!email && (
            <div id="to-email" onClick={clearRecipientData}>
              <img src={SendViaEmail} />
              <span>{email}</span>
            </div>
          )}

          {!!recipient && (
            <div id="to-recipient" onClick={clearRecipientData}>
              <Avatar
                user={recipient}
                size="lg"
                showRepIcon={isRep(recipient)}
              />
              <span>{recipient.displayName}</span>
            </div>
          )}
        </div>

        <div id="optional-message-body">
          <TextareaAutosize
            id="optional-message"
            rows={1}
            ref={ref}
            maxLength={500}
            placeholder={placeholder || t('typeOptionalMessage')}
            style={{ minHeight: '32px' }}
            {...(onChange && { onChange: onChange })}
          />

          <div id="message-preview" className={messagePreviewClasses}>
            <ChatMessage
              inDock={false}
              initIndex={-1}
              isPreview={true}
              me={false}
              forwarded={forwarded}
              message={message}
              messageActionsEnabled={false}
              renderComment={!!comment} //Forward Message Comment
            />
          </div>
        </div>
      </div>
    )
  }
)

export default OptionalMessagePreview
