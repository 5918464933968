import React, { useContext, useRef, useState } from 'react'
import {
  ERROR,
  I18N,
  MESSAGE_TYPES,
  MODALS,
  ROUTES,
  SUPPORT_LINK,
} from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { ChatContext } from '@web/js/context/ChatContext'
import { Connection, HCP, Message, RecipientEmail, Rep } from '@web/_types'
import { ChatService } from '@web/_services'
import { useNavigate } from 'react-router-dom'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import RecipientSelector from '@web/js/components/RecipientSelector'
import OptionalMessagePreview from '@web/js/components/OptionalMessagePreview'
import { AuthContext } from '@web/js/context/AuthContext'

interface SendDBCContactModalProps {
  rep: Rep
}

const SendDBCContactModal: React.FC<SendDBCContactModalProps> = ({ rep }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(I18N.namespaces.web)

  const { user } = useContext(AuthContext)
  const { hideModal, showModal } = useContext(ModalContext)
  const { getChannels } = useContext(ChatContext)

  const isSendingRef = useRef(false)
  const optionalMessageRef = useRef<HTMLTextAreaElement>(null)

  const [recipient, setRecipient] = useState<Connection | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [emailStatus, setEmailStatus] = useState<string | null>(null)

  const shareContactMessage = {
    metadata: {
      shareContact: {
        contact: rep,
        contactId: rep.id,
        type: MESSAGE_TYPES.shareRep,
      },
    },
    type: 'SHARE_REP',
    status: 'DELIVERED',
    user: user as HCP,
  }

  const [message] = useState<Message>(shareContactMessage as Message)

  const handleAccept = async () => {
    if (isSendingRef.current) return

    //using ref because setting state is too slow
    isSendingRef.current = true
    const optionalMessage = optionalMessageRef.current?.value.trim()
      ? optionalMessageRef.current?.value.trim()
      : null

    if (!rep.id || (!recipient && !email)) return

    try {
      const data = {
        contactUserId: rep.id,
        ...(!!recipient && { targetUserId: recipient.id }),
        ...(!!email && { targetEmail: email }),
        ...(!!optionalMessage && { optionalMessage }),
      }

      const sendShareDBCContactResponse = await ChatService.sendShareDBCContact(
        data
      )
      const channelId = sendShareDBCContactResponse.data.channelId
      if (channelId) await getChannels()
      routeToThread(channelId as string)
    } catch (error) {
      let errorResponse = null
      let name = email

      if (error.response.data.code) errorResponse = error.response.data.code
      if (error.response.data.autoConnectedName)
        name = error.response.data.autoConnectedName

      //if error code exists without name/email present, trigger default error
      if (
        (!!errorResponse && !name) ||
        (errorResponse === ERROR.crossCompany &&
          !error.response.data.autoConnectedName)
      ) {
        errorResponse = null
      }

      let messageError
      switch (errorResponse) {
        case ERROR.chatNotEnabled:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorCannotReceiveChats', {
              name,
            }),
          }
          break
        case ERROR.crossCompany:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorPharmaContactsDiffCompany', {
              name,
            }),
          }
          break
        case ERROR.disconnected:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorMustBeConnected', {
              name,
            }),
          }
          break
        case ERROR.contactShareForbidden:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('contactsCannotBeShared', {
              name,
            }),
          }
          break
        default:
          messageError = {
            title: t('errorSomethingWentWrongTitle'),
            body: t('errorNotAllowed', {
              support: SUPPORT_LINK,
            }),
          }
          break
      }

      if (messageError) {
        showModal({
          name: MODALS.GENERIC_ERROR_MODAL,
          data: {
            error: messageError,
          },
        })
      }
    } finally {
      isSendingRef.current = false
    }
  }

  const setRecipientEmail = ({ email, emailStatus }: RecipientEmail) => {
    setEmail(email)
    setEmailStatus(emailStatus)
  }

  const clearRecipientEmail = () => {
    setRecipient(null)
    setEmail(null)
    setEmailStatus(null)
  }

  const routeToThread = (channelId: string) => {
    const route = `${ROUTES.chat}/${channelId}`
    navigate(route)
    hideModal()
  }

  const selectRecipient = (recipient: Connection) => {
    setRecipient(recipient)
  }

  const loadUsers = (q: string, signal: AbortSignal) =>
    ChatService.getShareDBCContactRecipients(q.trim(), rep.id, signal)

  return (
    <Modal size="lg" title={t('sendMessage')} onClose={hideModal}>
      <ModalBody>
        {!email && !recipient && (
          <RecipientSelector
            loadData={loadUsers}
            setRecipient={selectRecipient}
            setEmail={setRecipientEmail}
          />
        )}

        {!!message && (!!email || !!recipient) && (
          <OptionalMessagePreview
            ref={optionalMessageRef}
            placeholder={t('typeSomething')}
            message={message}
            recipient={recipient}
            email={email}
            emailStatus={emailStatus}
            comment={false}
            clearRecipientData={clearRecipientEmail}
            useViewTextBanner={true}
          />
        )}
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onCancel={hideModal}
        onCancelText={t('cancel')}
        onAcceptText={t('send')}
        onAccept={handleAccept}
        disabled={!email && !recipient}
      />
    </Modal>
  )
}
export default SendDBCContactModal
