import React, { useRef, useState, useContext } from 'react'
import './ChatMessages.scss'
import { useTranslation } from 'react-i18next'
import {
  CACHE_EVENT,
  CONTENT_FRAGMENT,
  I18N,
  LOCALSTORAGE,
  LOGGING,
  MESSAGE_TYPES,
  ROLES,
  ROUTES,
} from '@web/_constants'
import {
  Message,
  CachedMessage,
  Connection,
  Content,
  HCP,
  Rep,
  ContentFragment,
  ContentIdentifier,
  ContentIdentifierSystemType,
} from '@web/_types'
import { classNames, stripHtml, formatText } from '@web/_utils'
import DOMPurify from 'dompurify'
import { hasProfile, isHCP, isRep, isSent } from '@web/_guards'
import { isCachedMessage, isMessage } from '@web/_guards/message'
import { createLog } from '@web/js/redux/logger/loggerActions'
import Linkify from 'linkify-react'
import * as linkify from 'linkifyjs'
import parse, {
  attributesToProps,
  DOMNode,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { isText, Element } from 'domhandler'
import Avatar from '@web/js/components/Avatar'
import Image from '@web/js/components/Image'
import ContactCard from '../ContactCard'
import { useLocalStorage } from '@web/common/hooks'
import { useDispatch } from 'react-redux'
import { ChatContext } from '@web/js/context/ChatContext'
import _ from 'lodash'
import { AuthContext } from '@web/js/context/AuthContext'
import BrandResourceCard from '../BrandResourceCard'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'

interface ChatMessageProps {
  isPreview?: boolean
  renderComment?: boolean
  messageActionsEnabled: boolean
  message: Message | CachedMessage
  initIndex: number
  me: boolean
  inDock: boolean
  forwarded: boolean
}

interface ILogLinkEvent {
  link: string
  contentId?: string | null
  footerId?: string | null
  isMainMessage?: boolean
  contentIdentifier?: ContentIdentifier
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  messageActionsEnabled,
  isPreview = false,
  forwarded = false,
  renderComment = false,
  message,
  me,
  initIndex,
  inDock,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useContext(AuthContext)
  const { startForwardMessage } = useContext(ChatContext)
  const [popupAlign, setPopupAlign] = useState('align-center align-top')
  const [, setJoinType] = useLocalStorage<string | null>(
    LOCALSTORAGE.joinType,
    null
  )

  const chatMessageRef = useRef<HTMLDivElement>(null)
  const optionalMessageRef = useRef<HTMLDivElement>(null)

  const shouldRenderFunctions = !isPreview && messageActionsEnabled
  const isBrandResource =
    isMessage(message) && message.type === MESSAGE_TYPES.brandResource

  const sharedContact =
    isMessage(message) && !!message.metadata?.shareContact?.contact
      ? message.metadata.shareContact.contact
      : null

  const isCLMShareLink =
    isMessage(message) &&
    !!message.metadata?.shareLink &&
    message.metadata.shareLink.sentMessageType === 'Remote_CLM_Link_vod'

  const logLinkEvent = ({
    contentId = null,
    contentIdentifier = null,
    footerId = null,
    isMainMessage = true,
    link,
  }: ILogLinkEvent) => {
    const eventMessage = {
      messageId: message.id,
      contentId,
      footerId,
      link,
      contentIdentifier,
      isMainMessage,
    }

    //if meeting link is clicked, set joinType to CHAT
    const regex =
      /(https:\/\/engage.?)([\w\-.]+)(\.(com\/m.html))(\?j=[0-9]+&pwd=[0-9]+)/g
    const match = link.match(regex)
    if (match || isCLMShareLink) setJoinType(LOGGING.JOIN_TYPE.CHAT)

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.CHAT_LINK_CLICK,
        JSON.stringify(eventMessage)
      )
    )
  }

  const formatFooter = (content: Content) => {
    let footer = content.footer as string

    footer = DOMPurify.sanitize(footer, {
      ALLOWED_TAGS: ['a'],
      KEEP_CONTENT: true,
    })

    const options: HTMLReactParserOptions = {
      replace: (domNode: DOMNode) => {
        if (
          domNode instanceof Element &&
          domNode.attribs &&
          domNode.name === 'a'
        ) {
          const props = attributesToProps(domNode.attribs)
          const link = props.href as string
          const footerId = content.footerMap ? content.footerMap[link] : null
          const contentId = content.id
          const child = domNode.children[0]

          return (
            <a
              className="chat-message--footer--link text-small"
              onClick={() => logLinkEvent({ link, footerId, contentId })}
              target="_blank"
              {...props}
            >
              {isText(child) && child.data}
            </a>
          )
        }
      },
    }

    return parse(footer, options)
  }

  const renderSharedContentLink = (content: Content) => {
    const linkName = content.linkName
      ? content.linkName
      : stripHtml(content.linkBody as string)

    return (
      <a
        href={content.linkUrl as string}
        onClick={() =>
          logLinkEvent({
            link: content.linkUrl as string,
            contentId: content.id,
          })
        }
        target="_blank"
      >
        {content.linkImageUrl && (
          <Image
            src={content.linkImageUrl}
            alt={linkName}
            showPlaceholder={true}
          />
        )}
        <span>{linkName}</span>
      </a>
    )
  }

  const renderSharedContent = () => {
    if (!message.content) return null
    const content = message.content as Content

    return (
      <div className="chat-message--body shared-content">
        {!!content.header && (
          <div className="chat-message--header">{content.header}</div>
        )}

        {!!content.linkUrl && (
          <div
            className={`chat-message--link ${
              content.linkImageUrl ? 'chat-message--link-image' : ''
            }`}
          >
            <div className="container">
              {renderSharedContentLink(message.content as Content)}
            </div>
          </div>
        )}

        {!content.header && !content.linkUrl && !!content.message && (
          <div className="shared-content--message">
            <Linkify
              options={{
                attributes: {
                  onClick: (event: React.MouseEvent) => {
                    if (event.target instanceof HTMLAnchorElement) {
                      const link = event.target.getAttribute('href') as string
                      logLinkEvent({ link, contentId: content.id })
                    }
                  },
                },
                target: { url: '_blank' },
              }}
            >
              {formatText(content.message)}
            </Linkify>
          </div>
        )}

        {!!content.footer && (
          <div className="chat-message--footer text-small">
            {formatFooter(content)}
          </div>
        )}
      </div>
    )
  }

  const renderContentEnvelope = () => {
    const content = message.content as Content
    const fragments = content.fragments as ContentFragment[]
    if (!fragments.length) return null

    return (
      <div className="chat-message--body content-envelope">
        {_.map(fragments, (fragment, i) => {
          const type = fragment.type

          if (type === CONTENT_FRAGMENT.text) {
            return (
              <div
                key={`${message.id}_fragment_${i}`}
                className="chat-message--header"
              >
                {fragment.text}
              </div>
            )
          }

          if (type === CONTENT_FRAGMENT.thumbnailLink) {
            return (
              <div
                key={`${message.id}_fragment_${i}`}
                className={`chat-message--link ${
                  fragment.imageUrl ? 'chat-message--link-image' : ''
                }`}
              >
                <div className="container">
                  <a
                    href={fragment.linkUrl as string}
                    onClick={() =>
                      logLinkEvent({
                        link: fragment.linkUrl as string,
                        contentIdentifier: fragment.contentIdentifier,
                      })
                    }
                    target="_blank"
                  >
                    {fragment.imageUrl && (
                      <Image
                        src={fragment.imageUrl}
                        alt={fragment.text}
                        showPlaceholder={true}
                      />
                    )}
                    <span>{fragment.text}</span>
                  </a>
                </div>
              </div>
            )
          }

          if (type === CONTENT_FRAGMENT.link) {
            return (
              <a
                key={`${message.id}_fragment_${i}`}
                className="chat-message--footer--link text-small"
                onClick={() =>
                  logLinkEvent({
                    link: fragment.linkUrl as string,
                    contentIdentifier: fragment.contentIdentifier,
                  })
                }
                target="_blank"
                href={fragment.linkUrl as string}
              >
                {fragment.text}
              </a>
            )
          }
        })}
      </div>
    )
  }

  const goToProfile = (connection: Connection, actionDetail: string) => {
    const path = isRep(connection) ? ROLES.rep : ROLES.hcp

    const eventMessage = {
      profileId: connection.id,
      actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
      actionDetail,
      referenceId: message.id,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )

    navigate(`/${path.toLowerCase()}/${connection.id}`, {
      state: { connection },
    })
  }

  const renderSharedContact = () => {
    const sharedContactTyped = sharedContact as Connection
    const isMe = sharedContactTyped.id === user?.id
    const clickable = hasProfile(sharedContactTyped) && !isMe
    const sharedContactCx = {
      ['chat-message--body']: true,
      ['shared-contact']: true,
      clickable,
    }

    const sharedContactClasses = classNames(sharedContactCx)

    const handleOnClick = () => {
      logLinkEvent({ link: sharedContactTyped.id })
      goToProfile(sharedContactTyped, LOGGING.ACTION_DETAIL.SHARED_CONTACT)
    }

    return (
      <div className={sharedContactClasses}>
        <div
          className="container"
          {...(clickable && { onClick: handleOnClick })}
        >
          <ContactCard
            showGroup={isRep(sharedContactTyped)}
            showTitle={isHCP(sharedContactTyped)}
            showArrow={clickable}
            contact={sharedContactTyped}
          />
        </div>
      </div>
    )
  }

  const goToBrandProfile = (brandId: string, brandResourceId: string) => {
    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.BRAND_PROFILE_ACTIVITY,
        JSON.stringify({
          brandId,
          referenceId: brandResourceId,
          actionType: LOGGING.ACTION_TYPE.BRAND_PROFILE,
          actionCategory: LOGGING.ACTION_TYPE.BRAND_RESOURCE_CHAT,
        })
      )
    )

    logLinkEvent({
      contentIdentifier: {
        system: LOGGING.SYSTEM.EC as ContentIdentifierSystemType,
        referenceType: LOGGING.REFERENCE_TYPE.BRAND_RESOURCE,
        id: brandResourceId,
      },
      isMainMessage: false,
      link: brandId,
    })

    navigate(`${ROUTES.brand}/${brandId}`)
  }

  const renderBrandResource = () => {
    const brandResource = (message as Message).metadata?.brandResource
    if (!brandResource) return null
    const brand = brandResource.brand

    let regulatoryHTML: null | string = null

    if (brand.regStatementHtml) {
      const regulatoryText = (brand.regStatementHtml as string).replace(
        /(?:\\r\\n|\\r|\\n)/g,
        '<br />'
      )

      regulatoryHTML = DOMPurify.sanitize(regulatoryText, {
        ALLOWED_TAGS: ['b', 'i', 'a', 'ol', 'ul', 'li', 'br', 'p'],
      })
    }

    return (
      <div className="chat-message--body share-resource">
        <div>
          <BrandResourceCard
            onClick={() =>
              logLinkEvent({
                contentIdentifier: {
                  system: LOGGING.SYSTEM.EC as ContentIdentifierSystemType,
                  referenceType: LOGGING.REFERENCE_TYPE.BRAND_RESOURCE,
                  id: brandResource.resource.id,
                },
                link: brandResource.resource.value,
              })
            }
            brandId={brand.id}
            actionCategory={LOGGING.ACTION_TYPE.BRAND_RESOURCE_CHAT}
            resource={brandResource.resource}
          />
        </div>

        <div className="resource-details">
          <div className="resource-title">{brand.name}</div>
          {brand.nonProprietaryName && (
            <div className="resource-subtitle">{brand.nonProprietaryName}</div>
          )}
          <div className="resource-learn-more">
            <Button
              size="md"
              outline
              onClick={() =>
                goToBrandProfile(brand.id, brandResource.resource.id)
              }
              {...(isPreview && { classes: 'no-click' })}
            >
              {t('learnMore')}
            </Button>
          </div>
          {regulatoryHTML && (
            <div
              className="resource-info text-small"
              dangerouslySetInnerHTML={{
                __html: regulatoryHTML,
              }}
            />
          )}
          {!!brandResource.footers.length && (
            <div className="resource-links">
              {_.map(brandResource.footers, (link) => {
                return (
                  <a
                    className="resource-link"
                    key={`resource-link_${link.id}`}
                    onClick={() => {
                      dispatch(
                        createLog(
                          LOGGING.EVENT_TYPES.BRAND_PROFILE_ACTIVITY,
                          JSON.stringify({
                            brandId: brand.id,
                            referenceId: brandResource.resource.id,
                            actionType: LOGGING.ACTION_TYPE.REGULATORY_LINK,
                            actionCategory:
                              LOGGING.ACTION_TYPE.BRAND_RESOURCE_CHAT,
                          })
                        )
                      )

                      logLinkEvent({
                        link: link.url,
                        footerId: link.id,
                        isMainMessage: false,
                        contentIdentifier: {
                          system: LOGGING.SYSTEM
                            .EC as ContentIdentifierSystemType,
                          id: brandResource.resource.id,
                          referenceType: LOGGING.REFERENCE_TYPE.BRAND_RESOURCE,
                        },
                      })
                    }}
                    href={link.url}
                    target="_blank"
                  >
                    {link.name}
                  </a>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderMessageBody = (
    body: string,
    isMainMessage = true,
    classes = ''
  ) => {
    return (
      <div className={`chat-message--body ${classes}`}>
        <Linkify
          options={{
            attributes: {
              onClick: (event: React.MouseEvent) => {
                if (event.target instanceof HTMLAnchorElement) {
                  const link = event.target.getAttribute('href') as string
                  logLinkEvent({ link, isMainMessage })
                }
              },
            },
            target: { url: '_blank' },
          }}
        >
          {formatText(body)}
        </Linkify>
      </div>
    )
  }

  const renderForwardMessageFooter = (author: HCP | Rep) => {
    const userIsAuthor = author.id === (user as HCP).id
    const clickable = !isPreview && !userIsAuthor && hasProfile(author)

    const fcx = {
      'forward-user-footer': true,
      clickable: clickable,
    }

    const forwardMessageFooterClasses = classNames(fcx)

    return (
      <div
        className={forwardMessageFooterClasses}
        {...(clickable && {
          onClick: () =>
            goToProfile(
              author,
              LOGGING.ACTION_DETAIL.CHAT_FORWARDED_MESSAGE_AUTHOR
            ),
        })}
      >
        <ForwardBtnIcon />
        <Avatar size="sm" user={author} />
        <div className="forward-user">
          {author.displayName}
          {isRep(author) && !!author.group?.name && (
            <span>{` • ${author.group.name}`}</span>
          )}
        </div>
      </div>
    )
  }

  const renderMessageFunctions = (isOptionalMessage = false) => {
    const currentMessage = message as Message
    const author = currentMessage.author
    const isCopyable =
      shouldRenderFunctions &&
      isMessage(message) &&
      !_.includes(
        [
          MESSAGE_TYPES.contentEnvelope,
          MESSAGE_TYPES.brandResource,
          MESSAGE_TYPES.shareHCP,
          MESSAGE_TYPES.shareRep,
          MESSAGE_TYPES.sample,
          MESSAGE_TYPES.approvedContent,
        ],
        message.type
      )

    if (
      (!isOptionalMessage && author && !hasProfile(author)) ||
      (sharedContact && !hasProfile(sharedContact))
    )
      return null

    let hasLink = false
    if (currentMessage.type === MESSAGE_TYPES.plainText || isOptionalMessage) {
      const messageBody = isOptionalMessage
        ? currentMessage.forwardedComment
        : currentMessage.body

      if (messageBody) hasLink = !!linkify.find(messageBody as string).length
    }

    const showExplicitForward =
      !me &&
      !inDock &&
      ((!isOptionalMessage &&
        currentMessage.type !== MESSAGE_TYPES.plainText) ||
        hasLink)

    return (
      <div
        className="chat-message--actions"
        onMouseEnter={(event) => openMessageActions(event, isOptionalMessage)}
      >
        {showExplicitForward && (
          <div
            className="chat-message--actions-forward"
            onClick={() =>
              startForwardMessage(currentMessage, isOptionalMessage)
            }
          >
            <ForwardBtnIcon />
          </div>
        )}

        <div className="chat-message--actions-menu-wrapper">
          <div className="chat-message--actions-menu">
            <KabobIcon />
          </div>

          <div className={`chat-message--actions-popup ${popupAlign}`}>
            <div className="chat-message--actions-popup-body">
              <ul>
                <li
                  onClick={() =>
                    startForwardMessage(currentMessage, isOptionalMessage)
                  }
                >
                  <ForwardIcon />
                  {t('forward')}
                </li>
                {(isCopyable || isOptionalMessage) && (
                  <li onClick={copyMessage}>
                    <CopyIcon />
                    {t('copy')}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const openMessageActions = (
    e: React.MouseEvent<HTMLDivElement>,
    isOptionalMessage: boolean
  ) => {
    const actionLoc = e.currentTarget.getBoundingClientRect()
    const messageBody = isOptionalMessage
      ? optionalMessageRef.current
      : chatMessageRef.current
    const chatMessageLoc = messageBody?.getBoundingClientRect()
    const chatMessagesWrapperLoc = document
      .getElementById('chat-messages-wrapper')
      ?.getBoundingClientRect()

    if (chatMessageLoc) {
      const verticalAlign =
        actionLoc.top - (chatMessagesWrapperLoc as DOMRect).top < 100
          ? 'align-bottom'
          : 'align-top'
      const alignLeft = actionLoc.left - chatMessageLoc.left < 60
      const alignRight = chatMessageLoc.right - actionLoc.right < 60
      const horizontalAlign = alignLeft
        ? 'align-left'
        : alignRight
          ? 'align-right'
          : 'align-center'
      setPopupAlign(`${horizontalAlign} ${verticalAlign}`)
    }
  }

  const copyMessage = () => {
    navigator.clipboard.writeText(message.body as string)
  }

  const chatMessageClasses = classNames({
    'chat-message': true,
    me,
    previewing: isPreview,
    forwarded: !isPreview && forwarded,
    resource: isBrandResource,
  })

  const chatMessageStatusClasses = classNames({
    'chat-message--status': true,
    'text-extra-small': true,
    right: true,
    alert: message.status === CACHE_EVENT.failed,
  })

  const chatOptionalMessageClasses = classNames({
    'chat-message': true,
    'optional-message': true,
    me,
  })

  return (
    <>
      {!isPreview && !!(message as Message).forwardedComment && (
        <div className={chatOptionalMessageClasses} ref={optionalMessageRef}>
          <div className="chat-message--body-wrapper">
            {renderMessageBody(
              (message as Message).forwardedComment as string,
              false
            )}
          </div>

          {shouldRenderFunctions && renderMessageFunctions(true)}
        </div>
      )}

      <div className={chatMessageClasses} ref={chatMessageRef}>
        {!me && !isPreview && message.user && (
          <div className="chat-message--avatar">
            <Avatar size="md" user={message.user} />
          </div>
        )}

        {!renderComment && (
          <div className="chat-message--body-wrapper">
            {!!sharedContact && renderSharedContact()}
            {isCLMShareLink &&
              message.body &&
              renderMessageBody(message.body, true, 'share-link')}

            {!sharedContact &&
              !isCLMShareLink &&
              message.body &&
              renderMessageBody(message.body)}

            {isMessage(message) &&
              message.type === MESSAGE_TYPES.approvedContent &&
              renderSharedContent()}

            {isMessage(message) &&
              message.type === MESSAGE_TYPES.brandResource &&
              renderBrandResource()}

            {isMessage(message) &&
              message.type === MESSAGE_TYPES.contentEnvelope &&
              renderContentEnvelope()}

            {isMessage(message) &&
              !isPreview &&
              forwarded &&
              !!message.author &&
              renderForwardMessageFooter(message.author)}
          </div>
        )}

        {renderComment && (
          <div className="chat-message--body-wrapper">
            {renderMessageBody((message as Message).forwardedComment as string)}
          </div>
        )}

        {shouldRenderFunctions && renderMessageFunctions()}
      </div>

      {isCachedMessage(message) && (
        <div className={chatMessageStatusClasses}>
          {t(`cache.${message.status.toLowerCase()}`)}
        </div>
      )}

      {isSent(message) &&
        isMessage(message) &&
        me &&
        message.index === initIndex &&
        !message.external && (
          <div className="chat-message--status text-extra-small right">
            {t('cache.delivered')}
          </div>
        )}

      {isSent(message) && isMessage(message) && me && message.external && (
        <div className="chat-message--status text-extra-small right">
          {t('cache.sentViaEmail')}
        </div>
      )}

      {isMessage(message) &&
        isPreview &&
        forwarded &&
        renderForwardMessageFooter(
          (renderComment || !message.author ? message.user : message.author) as
            | HCP
            | Rep
        )}
    </>
  )
}

export default React.memo(ChatMessage)

const KabobIcon = () => {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
        fill="#8E8E93"
      />
    </svg>
  )
}

const CopyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5429 0.685547H6.51428C6.18087 0.685547 5.86111 0.820404 5.62535 1.06045C5.38959 1.3005 5.25714 1.62607 5.25714 1.96555V12.2055C5.25714 12.545 5.38959 12.8706 5.62535 13.1106C5.86111 13.3507 6.18087 13.4855 6.51428 13.4855H14.0571C14.3906 13.4855 14.7103 13.3507 14.9461 13.1106C15.1818 12.8706 15.3143 12.545 15.3143 12.2055V4.52555L11.5429 0.685547Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.13614 0.580132C5.49951 0.21016 5.99499 0 6.5143 0H11.5429C11.7269 0 11.9032 0.0739512 12.0321 0.205229L15.8035 4.04523C15.9295 4.17345 16 4.34599 16 4.52571V12.2057C16 12.7231 15.7983 13.2217 15.4353 13.5913C15.0719 13.9613 14.5765 14.1714 14.0572 14.1714H6.5143C5.99499 14.1714 5.49951 13.9613 5.13614 13.5913C4.77319 13.2217 4.57144 12.7231 4.57144 12.2057V1.96571C4.57144 1.44832 4.77319 0.949685 5.13614 0.580132ZM6.5143 1.37143C6.36677 1.37143 6.22274 1.43098 6.11459 1.5411C6.00602 1.65164 5.94287 1.80415 5.94287 1.96571V12.2057C5.94287 12.3673 6.00602 12.5198 6.11459 12.6303C6.22274 12.7404 6.36677 12.8 6.5143 12.8H14.0572C14.2047 12.8 14.3487 12.7404 14.4569 12.6303C14.5654 12.5198 14.6286 12.3673 14.6286 12.2057V4.80613L11.2552 1.37143H6.5143Z"
        fill="black"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6571 0C12.0359 0 12.3429 0.307005 12.3429 0.685714V3.65714H15.3143C15.693 3.65714 16 3.96415 16 4.34286C16 4.72157 15.693 5.02857 15.3143 5.02857H11.6571C11.2784 5.02857 10.9714 4.72157 10.9714 4.34286V0.685714C10.9714 0.307005 11.2784 0 11.6571 0Z"
        fill="black"
        fillOpacity="0.9"
      />
      <path
        d="M6.97141 2.51416H1.94284C1.60943 2.51416 1.28967 2.64902 1.05391 2.88906C0.818148 3.12911 0.685699 3.45468 0.685699 3.79416V14.0342C0.685699 14.3736 0.818148 14.6992 1.05391 14.9393C1.28967 15.1793 1.60943 15.3142 1.94284 15.3142H9.4857C9.81911 15.3142 10.1389 15.1793 10.3746 14.9393C10.6104 14.6992 10.7428 14.3736 10.7428 14.0342V6.35416L6.97141 2.51416Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.564701 2.40875C0.928067 2.03877 1.42355 1.82861 1.94286 1.82861H6.97143C7.15543 1.82861 7.33172 1.90256 7.46065 2.03384L11.2321 5.87384C11.358 6.00207 11.4286 6.1746 11.4286 6.35433V14.0343C11.4286 14.5517 11.2268 15.0504 10.8639 15.4199C10.5005 15.7899 10.005 16 9.48571 16H1.94286C1.42355 16 0.928067 15.7899 0.564701 15.4199C0.201747 15.0504 0 14.5517 0 14.0343V3.79433C0 3.27693 0.201747 2.7783 0.564701 2.40875ZM1.94286 3.20004C1.79533 3.20004 1.6513 3.2596 1.54314 3.36972C1.43458 3.48026 1.37143 3.63277 1.37143 3.79433V14.0343C1.37143 14.1959 1.43458 14.3484 1.54314 14.4589C1.6513 14.5691 1.79533 14.6286 1.94286 14.6286H9.48571C9.63324 14.6286 9.77727 14.5691 9.88543 14.4589C9.99399 14.3484 10.0571 14.1959 10.0571 14.0343V6.63475L6.68377 3.20004H1.94286Z"
        fill="black"
        fillOpacity="0.9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08571 1.82861C7.46442 1.82861 7.77142 2.13562 7.77142 2.51433V5.48576H10.7429C11.1216 5.48576 11.4286 5.79276 11.4286 6.17147C11.4286 6.55018 11.1216 6.85718 10.7429 6.85718H7.08571C6.707 6.85718 6.39999 6.55018 6.39999 6.17147V2.51433C6.39999 2.13562 6.707 1.82861 7.08571 1.82861Z"
        fill="black"
        fillOpacity="0.9"
      />
    </svg>
  )
}

const ForwardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75218 1L14.55 6.44774L9.75218 11.8955M1.99997 15.1207L1.99997 8.78261C1.99997 7.5093 2.90904 6.47709 4.03042 6.47709L13.4991 6.47709"
        stroke="black"
        strokeOpacity="0.9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const ForwardBtnIcon = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5767 0.225714C6.96982 -0.111244 7.56166 -0.065718 7.89862 0.3274L11.6486 4.7024C11.9496 5.05348 11.9496 5.57155 11.6486 5.92263L7.89862 10.2976C7.56166 10.6908 6.96982 10.7363 6.5767 10.3993C6.18358 10.0624 6.13806 9.47052 6.47502 9.0774L8.90534 6.24202L2.59919 6.24202C2.27568 6.24514 2.13891 6.33823 2.07241 6.4113C1.99462 6.49677 1.87501 6.70821 1.875 7.18743C1.875 7.18746 1.875 7.1874 1.875 7.18743L1.87553 12.1874C1.87558 12.7052 1.45589 13.125 0.938124 13.125C0.420357 13.1251 0.00057973 12.7054 0.000525266 12.1876L0 7.18762C0 6.41689 0.192884 5.69076 0.685755 5.14923C1.1897 4.59555 1.88107 4.37252 2.5879 4.36705L2.59516 4.367L8.89163 4.36702L6.47502 1.54763C6.13806 1.15452 6.18358 0.562672 6.5767 0.225714Z"
        fill="#8E8E93"
      />
    </svg>
  )
}
