/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react'
import './ChatHeader.scss'
import { Channel, Connection } from '@web/_types'
import { useResponsive } from '@farfetch/react-context-responsive'
import Avatar from '../Avatar'
import { classNames, toCamelCase } from '@web/_utils'
import { hasProfile, isHCP, isRep } from '@web/_guards'
import CaretIcon from '@web/common/img/CaretIcon'
import { useNavigate } from 'react-router-dom'
import { ROUTES, ROLES, I18N, LOGGING, MODALS } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import ScheduleMeetingIcon from '@web/common/img/ScheduleMeetingIcon'
import MoreMenu from '../MoreMenu'
import { ModalContext } from '@web/js/context/ModalContext'
import { isMobile } from "react-device-detect";

type ChatHeaderProps = {
  reconnect: (channel: Channel, log?: string) => void
  channel: Channel
  connection?: Connection
  email?: string
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  channel,
  connection,
  email,
  reconnect,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { lessThan } = useResponsive()
  const { t } = useTranslation(I18N.namespaces.web)
  const { showModal } = useContext(ModalContext)

  if (!connection && !email) return null

  const logEvent = (actionType: string, actionDetail?: string) => {
    const eventMessage = {
      profileId: (connection as Connection).id,
      actionType,
      ...(actionDetail && { actionDetail }),
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  const disconnect = () => {
    showModal({
      name: MODALS.REMOVE_CONNECTION_MODAL,
      data: { connection },
    })

    logEvent(
      LOGGING.ACTION_TYPE.CLICK_REMOVE_CONNECTION_MENU,
      LOGGING.ACTION_DETAIL.CHAT_THREAD_HEADER_MENU
    )
  }

  const goToProfile = (connection: Connection, actionDetail: string) => {
    logEvent(LOGGING.ACTION_TYPE.VIEW_PROFILE, actionDetail)

    const path = connection && isRep(connection) ? ROLES.rep : ROLES.hcp
    navigate(`/${path.toLowerCase()}/${connection.id}`, {
      state: { connection },
    })
  }

  const call = () => {
    if (connection && isRep(connection) && connection.phoneNumber) {
      window.open(`tel:${connection.phoneNumber}`)
      logEvent(
        LOGGING.ACTION_TYPE.CLICK_PHONE,
        LOGGING.ACTION_DETAIL.CHAT_THREAD_HEADER_MENU
      )

      if (!connection.isContact) {
        reconnect(channel, LOGGING.ACTION_DETAIL.CHAT_THREAD_PHONE)
      }
    }
  }

  const scheduleMeeting = (
    hcpInitiatedSchLink: string,
    actionDetail: string
  ) => {
    logEvent(LOGGING.ACTION_TYPE.CLICK_SCHEDULE, actionDetail)
    window.open(hcpInitiatedSchLink)

    if (connection && isRep(connection) && !connection.isContact) {
      reconnect(channel, LOGGING.ACTION_DETAIL.CHAT_THREAD_SCHEDULE)
    }
  }

  const goToChat = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    navigate(ROUTES.chat)
  }

  const headerStyle =
    !!connection && isRep(connection)
      ? {
          background: `linear-gradient(90deg, ${connection.group.secondaryColor} 0%, ${connection.group.primaryColor} 100%)`,
        }
      : {}

  const noProfile =
    (!!connection && !hasProfile(connection as Connection)) || !connection

  const chatHeaderClasses = classNames({
    'no-profile': noProfile,
  })

  return (
    <div
      id="chat-header"
      style={headerStyle}
      className={chatHeaderClasses}
      onClick={() => {
        if (!noProfile)
          goToProfile(
            connection as Connection,
            LOGGING.ACTION_DETAIL.CHAT_THREAD_HEADER
          )
      }}
    >
      {lessThan.sm && (
        <div className="chat-header--back" onClick={(event) => goToChat(event)}>
          <CaretIcon color="white" />
        </div>
      )}

      {connection && (
        <>
          <div className="chat-header--user">
            <Avatar user={connection} invert={true} />
            <div className="chat-header--user-details">
              <div className="chat-header--user-name">
                {connection.displayName}
              </div>

              {isRep(connection) && connection.roleCategory && (
                <div className="chat-header--user-role">
                  {t(`roleCategory.${toCamelCase(connection.roleCategory)}`)}
                </div>
              )}

              {isHCP(connection) && connection.title && (
                <div className="chat-header--user-title">
                  {connection.title}
                </div>
              )}
            </div>
          </div>

          <div className="chat-header--actions">
            {isRep(connection) && connection.hcpInitiatedSchLink && (
              <div
                className="schedule-meeting"
                title={t('scheduleMeeting')}
                onClick={(e) => {
                  e.stopPropagation()
                  scheduleMeeting(
                    connection.hcpInitiatedSchLink as string,
                    LOGGING.ACTION_DETAIL.CHAT_THREAD_HEADER
                  )
                }}
              >
                <ScheduleMeetingIcon />
              </div>
            )}

            {!noProfile && (
              <MoreMenu id="chat-header--more-actions">
                <ul>
                  {isRep(connection) && connection.hcpInitiatedSchLink && (
                    <>
                      <li>
                        <button
                          onClick={() =>
                            scheduleMeeting(
                              connection.hcpInitiatedSchLink as string,
                              LOGGING.ACTION_DETAIL.CHAT_THREAD_HEADER_MENU
                            )
                          }
                        >
                          {t('scheduleMeeting')}
                        </button>
                      </li>
                      <li className="separator" />
                    </>
                  )}

                  {isRep(connection) && connection.phoneNumber && (isMobile) && (
                    <>
                      <li>
                        <button onClick={call}>{t('call')}</button>
                      </li>
                      <li className="separator" />
                    </>
                  )}

                  <li>
                    <button
                      onClick={() =>
                        goToProfile(
                          connection as Connection,
                          LOGGING.ACTION_DETAIL.CHAT_THREAD_HEADER_MENU
                        )
                      }
                    >
                      {t('viewProfile')}
                    </button>
                  </li>

                  {connection.isContact && (
                    <>
                      <li className="separator" />
                      <li>
                        <button className="danger" onClick={disconnect}>
                          {t('removeConnection')}
                        </button>
                      </li>
                    </>
                  )}

                  {!connection.isContact && !connection.invitePending && (
                    <>
                      <li className="separator" />
                      <li>
                        <button
                          onClick={() =>
                            reconnect(
                              channel,
                              LOGGING.ACTION_TYPE.CHAT_THREAD_HEADER_MENU
                            )
                          }
                        >
                          {t('connect')}
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </MoreMenu>
            )}
          </div>
        </>
      )}

      {email && (
        <div className="chat-header--user">
          <Avatar email={email} />

          <div className="chat-header--user-details">
            <div className="chat-header--user-name">{email}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatHeader
