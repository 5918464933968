import React, { ReactNode, useContext } from 'react'
import '../MainLayout/MainLayout.scss'
import './MainLayoutReduced.scss'
import { useNavigate } from 'react-router-dom'
import { useResponsive, useIsMobile } from '@farfetch/react-context-responsive'
import { classNames, getQueryStringParams } from '@web/_utils'
import Logo from '@web/common/img/Engage_Logo.svg'
import { ROUTES } from '@web/_constants'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'
import ModalRoot from '@web/js/modals/ModalRoot'

interface IMainLayoutReduced {
  children?: ReactNode
}

const MainLayoutReduced: React.FC<IMainLayoutReduced> = ({ children }) => {
  const navigate = useNavigate()
  const { mediaType, lessThan } = useResponsive()
  const { isMobile } = useIsMobile()
  const { clearDeeplinkState } = useContext(DeeplinkContext)
  const queryArgs = getQueryStringParams(window.location.search)
  const isGlassMode = !!queryArgs.vo && queryArgs.vo === '1'

  const mainLayoutClasses = classNames({
    reduced: true,
    [mediaType as string]: mediaType !== '_initial',
    xs: lessThan.sm,
    mobile: isMobile,
    'glass-mode': isGlassMode,
  })

  const goToOnboarding = () => {
    clearDeeplinkState()
    navigate(ROUTES.home)
  }

  return (
    <>
      <ModalRoot />
      <main id="main-layout" className={mainLayoutClasses}>
        <div id="main-layout-reduced--header">
          <div id="main-layout-reduced--header--logo" onClick={goToOnboarding}>
            <img src={Logo} />
          </div>
        </div>

        <div className="content">
          <div id="content-body">{children}</div>
        </div>
      </main>
    </>
  )
}

export default MainLayoutReduced
