import React from 'react'
import UnsupportedBrowser from '../UnsupportedBrowser'
import { useTranslation } from 'react-i18next'
import { I18N, ENGAGE_LANDING_URL } from '@web/_constants'
import './MeetingUnsupportedBrowser.scss'
import { QRCodeCanvas } from 'qrcode.react'
import * as DOMPurify from 'dompurify'

interface UnsupportedBrowserProps {
  meetingId: string
  meetingPwd: string
}

const MeetingUnsupportedBrowser: React.FC<UnsupportedBrowserProps> = ({
  meetingId,
  meetingPwd,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)

  return (
    <div id="meeting-unsupported">
      <UnsupportedBrowser />
      <div id="enter-link">{t('enterLink')}</div>
      <a href={window.location.href}>{window.location.href}</a>
      <p
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t('orScanCode')),
        }}
      />
      <QRCodeCanvas
        value={`${window.location.hostname}${ENGAGE_LANDING_URL}?j=${meetingId}&pwd=${meetingPwd}`}
      />
    </div>
  )
}

export default MeetingUnsupportedBrowser
