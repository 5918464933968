import React, { useContext, useEffect, useRef, useState } from 'react'
import './SignatureRequest.scss'
import { useTranslation } from 'react-i18next'
import { ENGAGE, I18N } from '@web/_constants'
import { StompService } from '@web/_services/StompService'
import { EngageService } from '@web/_services/EngageService'
import { EngageLoggingContext } from '@web/js/context/EngageLoggingContext'
import { EngageHostService } from '@web/_services/EngageHostService'

type SignatureRequestProps = {
  zoomUserId: string
  meetingId: string
  clientId: string
}

type StateRef = {
  sampleSignInId: string
  iFrameUrl: string
  postSignatureAutoCloseTimeout: ReturnType<typeof setTimeout> | null
}

const SignatureRequest: React.FC<SignatureRequestProps> = ({
  zoomUserId,
  meetingId,
  clientId,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const loggingContext = useContext(EngageLoggingContext)
  const { logClientEvent } = loggingContext
  const remoteSampleAttendeeId = `${meetingId}_${zoomUserId}`

  const [iFrameUrl, setIFrameUrl] = useState<string>('')
  const [showCancelModal, setShowCancelModal] = useState<string>('')
  const [sampleSignInId, setSampleSignInId] = useState<string>('')
  const [postSignatureAutoCloseTimeout, setPostSignatureAutoCloseTimeout] =
    useState<ReturnType<typeof setTimeout> | null>(null)

  const stateRef = useRef<StateRef>({
    sampleSignInId,
    iFrameUrl,
    postSignatureAutoCloseTimeout,
  })
  Object.assign(stateRef.current, {
    sampleSignInId,
    iFrameUrl,
    postSignatureAutoCloseTimeout,
  })

  const iFrameRef = useRef<HTMLIFrameElement>(null)

  const setupListeners = async () => {
    const messageToken = await EngageService.getMessageToken(meetingId)
    StompService.init(
      meetingId,
      zoomUserId,
      'dev',
      ENGAGE.SUPPORTED_REMOTE_SIGNATURES,
      messageToken,
      loggingContext
    )
    await StompService.activate(messageHandler, messageToken)

    const iFrameListener = (event: MessageEvent) => {
      if (event.data === ENGAGE.MSG_EVENT.CLOSE_SIGNATURE_PAGE) {
        setIFrameUrl('')
      } else if (event.data === ENGAGE.MSG_EVENT.POST_SIGNATURE) {
        const iFrameTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
          setIFrameUrl('')
        }, ENGAGE.POST_SIGNATURE_AUTO_CLOSE_TIMEOUT)
        setPostSignatureAutoCloseTimeout(iFrameTimeout)
      }
    }
    window.addEventListener('message', iFrameListener, true)
  }

  useEffect(() => {
    setupListeners().then()
    return () => {
      StompService.deactivate()
    }
  }, [])

  useEffect(() => {
    const reactRoot = document.getElementById('reactRoot')

    if (iFrameUrl) {
      reactRoot?.classList.add('elevate')
    } else {
      reactRoot?.classList.remove('elevate')
    }

    return () => {
      reactRoot?.classList.remove('elevate')
    }
  }, [iFrameUrl])

  const closeZoomAudio = (): void => {
    const button = document.getElementsByClassName('join-dialog__close');
    if (button.length) {
      (button[0] as HTMLButtonElement).click();
    }
  }

  const messageHandler = (message: Record<string, never>): void => {
    if (stateRef.current.postSignatureAutoCloseTimeout) {
      clearTimeout(stateRef.current.postSignatureAutoCloseTimeout)
    }

    if (message.action === ENGAGE.WS_EVENT.REQUEST_SIGNATURE) {
      setSampleSignInId(message.signinId)
      if (message.signinType === ENGAGE.WS_EVENT_TYPE.MEDICAL_INQUIRY) {
        const logMessage =
          ENGAGE.LOGGING.EVENT_MESSAGES.REQUEST_SIGNATURE_INQUIRIES.replace(
            '{0}',
            remoteSampleAttendeeId
          )
        logClientEvent(
          ENGAGE.LOGGING.EVENT_TYPES.REQUEST_SIGNATURE_INQUIRIES,
          null,
          logMessage
        ).then()
      } else if (message.signinType === ENGAGE.WS_EVENT_TYPE.SAMPLE) {
        const logMessage =
          ENGAGE.LOGGING.EVENT_MESSAGES.REQUEST_SIGNATURE_SAMPLES.replace(
            '{0}',
            remoteSampleAttendeeId
          )
        logClientEvent(
          ENGAGE.LOGGING.EVENT_TYPES.REQUEST_SIGNATURE_SAMPLES,
          null,
          logMessage
        ).then()
      } else if (message.signinType === ENGAGE.WS_EVENT_TYPE.AOC) {
        const logMessage = ENGAGE.LOGGING.EVENT_MESSAGES.REQUEST_SIGNATURE_AOC.replace(
          '{0}', remoteSampleAttendeeId
        )
        logClientEvent(
          ENGAGE.LOGGING.EVENT_TYPES.REQUEST_SIGNATURE_AOC,
          null,
          logMessage
        ).then()
      } else {
        console.error(`Received unexpected REQUEST_SIGNATURE signinType of ${message.signinType}`)
      }
      closeZoomAudio();
      setIFrameUrl(calculateIFrameUrl(message));
    } else if (message.action === ENGAGE.WS_EVENT.FINISH_SIGNATURE) {
      if (iFrameRef.current && iFrameRef.current.contentWindow) {
        iFrameRef.current.contentWindow.postMessage(
          ENGAGE.MSG_EVENT.FINISH_SIGNATURE,
          '*'
        )
      }
      const logMessage = ENGAGE.LOGGING.EVENT_MESSAGES.FINISH_SIGNATURE.replace(
        '{0}',
        remoteSampleAttendeeId
      )
      logClientEvent(
        ENGAGE.LOGGING.EVENT_TYPES.FINISH_SIGNATURE,
        null,
        logMessage
      ).then()
      setTimeout(() => {
        setIFrameUrl('')
      }, ENGAGE.FINISH_SIGNATURE_AUTO_CLOSE_TIMEOUT)
    } else if (
      message.action === ENGAGE.WS_EVENT.CANCEL_SIGNATURE &&
      Boolean(stateRef.current.iFrameUrl)
    ) {
      const logMessage = ENGAGE.LOGGING.EVENT_MESSAGES.CANCEL_SIGNATURE.replace(
        '{0}',
        remoteSampleAttendeeId
      )
      logClientEvent(
        ENGAGE.LOGGING.EVENT_TYPES.CANCEL_SIGNATURE,
        null,
        logMessage
      ).then()
      setShowCancelModal(message.hostName)
    }
  }

  const calculateIFrameUrl = (message: Record<string, never>): string => {
    if(message.engageCdnUrl) return `${message.engageCdnUrl}/${clientId}`

    let typePath
    switch(message.signinType) {
      case ENGAGE.WS_EVENT_TYPE.MEDICAL_INQUIRY:
        typePath = "/#/inquiries/"
        break
      case ENGAGE.WS_EVENT_TYPE.SAMPLE:
        typePath = "/#/signee/"
        break
      case ENGAGE.WS_EVENT_TYPE.AOC:
        typePath = "/#/aoc/"
        break
      default:
        return '' // unsupported type so don't open iframe
    }
    return `${EngageHostService.getEngageSampleBase()}${typePath}${message.signinId}/${clientId}`
  }

  const dismissCancelSignatureModal = () => {
    setIFrameUrl('')
    setShowCancelModal('')
  }

  return (
    <div>
      {Boolean(showCancelModal) && (
        <div className="cancel-signature-modal">
          <div className="modal-backdrop" />
          <div className="modal-body">
            <div className="hand-icon">✋</div>
            <div className="main-title">{t('request-canceled-message')}</div>
            <div className="sub-title">
              {t('user-canceled-sig-request-message', {
                hostName: showCancelModal,
              })}
            </div>
            <div
              className="button"
              onClick={() => dismissCancelSignatureModal()}
            >
              {t('ok')}
            </div>
          </div>
        </div>
      )}
      {Boolean(iFrameUrl) && (
        <iframe className="signature-frame" src={iFrameUrl} ref={iFrameRef} autoFocus={true} />
      )}
    </div>
  )
}

export default SignatureRequest
