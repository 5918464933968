import React from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { I18N } from '@web/_constants'
import './ZoomQRCode.scss'

interface ZoomQRCodeProps {
  qrCodeUrl: string
}

const ZoomQRCode: React.FC<ZoomQRCodeProps> = ({ qrCodeUrl }) => {
  const { t } = useTranslation(I18N.namespaces.web)

  return (
    <div id="qr-code-screen">
      <label id="top-question">{t('havingIssues')}</label>
      <label id="try-options">{t('tryOptions')}</label>
      <label id="open-email" className="nonbold">
        {t('openInvitationEmail')}
      </label>
      <label id="or">{t('or')}</label>
      <label id="scan-code" className="nonbold">
        {t('scanThisCode')}
      </label>
      <QRCodeCanvas value={qrCodeUrl} />
    </div>
  )
}

export default ZoomQRCode
