export const SUPPORT_EMAIL = 'support@veevaengage.com'

export const LOGO_EXT = '_sm.png'

export const ENGAGE_LANDING_URL = '/engage'
// Axios lowercases response headers
export const ENGAGE_SERVER_URL_HEADER = 'engage-server-url'

export const FAQ_URL = 'https://veevaengage.com/faq'
export const VEEVA_URL = 'https://www.veeva.com/'
export const MARKETING_SITE_URL = 'https://veevaengage.com'
export const SUPPORT_LINK = `<a href='mailto:support@veevaengage.com'>support@veevaengage.com</a>`

export const APP_LINKS = {
  engageAppStore: 'itms-apps://apple.com/app/id1155146582',
  engageConnectAppStore: 'itms-apps://apple.com/app/id1565084541',
  engagePlayStore:
    'https://play.google.com/store/apps/details?id=com.veeva.engage',
  engageConnectPlayStore:
    'https://play.google.com/store/apps/details?id=com.veeva.myveeva.forlifesciences',
  androidCN: 'https://engage.veevasfa.cn/android/veeva-engage-hcp-app.apk',
}

export const HOURS_24_SELECT = [...Array(25)].map((h, i) => ({
  value: i.toString(),
  label: i.toString(),
}))

export const HOURS_12_SELECT = [...Array(12)].map((h, i) => ({
  value: (i + 1).toString(),
  label: (i + 1).toString(),
}))

export const MINUTES_SELECT = [...Array(60)].map((h, i) => {
  const digits = i < 10 ? '0' + i.toString() : i.toString()
  return { value: digits, label: digits }
})

export const AM_PM_SELECT = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
]

export const COLORS = {
  black: 'rgba(0, 0, 0, 0.9)',
  blue: '#3d9bd6',
  lightBlue: '#e8f1ff',
  darkBlue: '#2372A4',
  gray: 'rgba(0, 0, 0, 0.3)',
  grayLight: '#E7E7E7',
  grayLighter: '#f3f3f3',
  midnight: '#1b2f54',
  red: '#E41B1B',
  white: '#ffffff',
  disabled: '#b3b3b3',
}

export const MESSAGE_TYPES = {
  approvedContent: 'APPROVED_CONTENT',
  brandResource: 'BRAND_RESOURCE',
  contentEnvelope: 'CONTENT_ENVELOPE',
  plainText: 'PLAINTEXT',
  sample: 'SAMPLE',
  shareHCP: 'SHARE_HCP',
  shareLink: 'SHARE_LINK',
  shareRep: 'SHARE_REP',
}

export const CHANNEL_TYPES = {
  partial: 'PARTIAL_CHANNEL',
  contact: 'CONTACT_CHANNEL',
}

export const EMAIL_STATUS = {
  autoConnect: 'AUTOCONNECT',
  hide: 'HIDE',
  valid: 'VALID',
  invalid: 'INVALID',
  self: 'SELF',
}

export const BREAKPOINTS = {
  xs: '480px',
  sm: '576px',
  md: '960px',
  lg: '1280px',
  xl: '1800px',
}

export const BREAKPOINTS_MAX = {
  xs: '479px',
  sm: '575px',
  md: '959px',
  lg: '1279px',
  xl: '1799px',
}

export const FULL_DAY_MINUTES = 1440

export const DAYS = {
  today: 'Today',
  tomorrow: 'Tomorrow',
}

export const PREV_PATH = {
  home: 'HOME',
  connections: 'CONNECTIONS',
}

export const WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const ROLE_CATEGORY_LIST = [
  'SALES',
  'REIMBURSEMENT',
  'MEDICAL',
  'EDUCATORS',
  'OPS_ADMIN',
]

export const RESOURCE_CATEGORIES: { [key: string]: string } = {
  hcp: 'HCP',
  patient: 'PATIENT',
  reimbursement: 'REIMBURSEMENT',
  patienthub: 'PATIENT_HUB',
  medinfo: 'MEDINFO',
  contactus: 'CONTACT_US',
}

export const ROUTES = {
  brand: '/brand',
  brandId: '/brand/:id',
  brandIdSlug: '/brand/:id/:slug',
  chat: '/chat',
  chatChannel: '/chat/:channel',
  connections: '/connections',
  connectionsId: '/connections/:id',
  consent: '/consent',
  content: '/content',
  dbc: '/dbc',
  dbcSignup: '/dbc-signup',
  editProfile: '/profile/edit',
  engage: '/engage',
  events: '/events',
  hcp: '/hcp',
  hcpId: '/hcp/:id',
  home: '/',
  invite: '/invite',
  join: '/join',
  joinMeeting: '/joinmeeting', //mobile standalone
  link: '/link',
  linkMobileWelcome: '/link/mobile/welcome',
  linkMobileLink: '/link/mobile/link',
  loginRep: '/login-rep',
  login: '/login',
  logout: '/logout',
  medications: '/medications',
  meeting: '/meeting',
  meetings: '/meetings',
  meetingsId: '/meetings/:id',
  name: '/name',
  office: '/office',
  officeCreate: '/office/create',
  officeEdit: '/office/:id/edit',
  officeId: '/office/:id',
  postMeeting: '/postmeeting',
  postMeetingRequest: '/postmeetingrequest',
  postPresentation: '/postpresentation',
  presentation: '/presentation',
  profile: '/profile',
  redirect: '/redirect',
  rep: '/rep',
  repId: '/rep/:id',
  scheduling: '/assets/scheduling',
  search: '/search',
  settings: '/settings',
  signup: '/signup',
  unsubscribe: '/unsubscribe',
  verify: '/verify',
  version: '/version',
}

export const NAVIGATE_TO = {
  BRAND_PROFILE: '/brand',
  CHAT: '/chat',
  CONTACTS: '/connections',
  EDIT_PROFILE: '/profile/edit',
  HCP_PROFILE: '/hcp',
  HOME: '/',
  LOGIN: '/login',
  ME: '/profile',
  MEETING: '/meetings',
  NOTIFICATION_SETTINGS: '/settings',
  OFFICE: '/office',
  ONBOARDING: '/',
  REP_PROFILE: '/rep',
}

export const NAV_LOCATIONS = {
  BRAND_PROFILE: 'BRAND_PROFILE',
  CHAT: 'CHAT',
  CONTACTS: 'CONTACTS',
  EDIT_PROFILE: 'EDIT_PROFILE',
  HCP_PROFILE: 'HCP_PROFILE',
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  ME: 'ME',
  MEETING: 'MEETING',
  NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
  OFFICE: 'OFFICE',
  ONBOARDING: 'ONBOARDING',
  REP_PROFILE: 'REP_PROFILE',
}

export const DEEPLINK_ACTIONS = {
  NAVIGATE: 'NAVIGATE',
  JOIN_MEETING: 'JOIN_MEETING',
  LOGIN: 'LOGIN',
  SCHEDULE: 'SCHEDULE',
  SWAP_ENV: 'SWAP_ENV',
  REVERSE_INVITE: 'REVERSE_INVITE',
  ERROR: 'ERROR',
}

export const DEEPLINK_ERRORS = {
  LINK_EXPIRED: 'LINK_EXPIRED',
  SIGN_IN_CHAT: 'SIGN_IN_CHAT',
  SIGN_IN_NOTIFICATIONS: 'SIGN_IN_NOTIFICATIONS',
  CANNOT_VIEW_PROFILE: 'CANNOT_VIEW_PROFILE',
  CANNOT_VIEW_MESSAGE: 'CANNOT_VIEW_MESSAGE',
  GENERIC: 'GENERIC',
  CUSTOM: 'CUSTOM',
}

export const AXIOS = {
  canceled: 'canceled',
}

export const SOCKET_EVENT = {
  joinuser: 'joinuser',
  connect: 'connect',
  disconnect: 'disconnect',
  message: 'message',
  connectError: 'connect_error',
}

export const SOCKET_EVENT_DISCONNECT_REASON = {
  ioClientDisconnect: 'io client disconnect',
}

export const SOCKET_EVENT_TYPE = {
  channel: 'CHANNEL',
  msg: 'MSG',
  remoteClmStarted: 'REMOTE_CLM_STARTED',
  typing: 'TYPING',
  unreadChanged: 'UNREAD_CHANGED',
}

export const CACHE_EVENT = {
  sending: 'SENDING',
  delivered: 'DELIVERED',
  failed: 'FAILED',
}

export const NOTIFICATION = {
  connectionRequest: 'cxRequest',
  newConnection: 'cxCreated',
  meetingRequest: 'schRequest',
  meetingAccepted: 'schAccepted',
  meetingDeclined: 'schDeclined',
  meetingAdvRequest: 'schAdvRequest',
  meetingAdvAccepted: 'schAdvAccepted',
  meetingAdvDeclined: 'schAdvDeclined',
  crmMeetingRequest: 'schCrmRequest',
  crmMeetingAccepted: 'schCrmAccepted',
  crmMeetingDeclined: 'schCrmDeclined',
}

export const SCHEDULING_NOTIFICATIONS = [
  NOTIFICATION.meetingRequest,
  NOTIFICATION.meetingAccepted,
  NOTIFICATION.meetingDeclined,
  NOTIFICATION.meetingAdvRequest,
  NOTIFICATION.meetingAdvAccepted,
  NOTIFICATION.meetingAdvDeclined,
  NOTIFICATION.crmMeetingRequest,
  NOTIFICATION.crmMeetingAccepted,
  NOTIFICATION.crmMeetingDeclined,
]

export const SCHEDULING_INVITE_NOTIFICATIONS = [
  NOTIFICATION.meetingRequest,
  NOTIFICATION.meetingAdvRequest,
]

export const SCHEDULING_ACCEPTED_NOTIFICATIONS = [
  NOTIFICATION.meetingAccepted,
  NOTIFICATION.meetingAdvAccepted,
  NOTIFICATION.crmMeetingAccepted,
]

export const SCHEDULING_DECLINED_NOTIFICATIONS = [
  NOTIFICATION.meetingDeclined,
  NOTIFICATION.meetingAdvDeclined,
  NOTIFICATION.crmMeetingDeclined,
]

export const SCHEDULING_SCH_NOTIFICATIONS = [
  NOTIFICATION.meetingAccepted,
  NOTIFICATION.meetingDeclined,
  NOTIFICATION.meetingRequest,
]

export const SCHEDULING_SCH_ADV_NOTIFICATIONS = [
  NOTIFICATION.meetingAdvAccepted,
  NOTIFICATION.meetingAdvRequest,
  NOTIFICATION.meetingAdvDeclined,
]

export const SCHEDULING_SCH_CRM_NOTIFICATIONS = [
  NOTIFICATION.crmMeetingRequest,
  NOTIFICATION.crmMeetingAccepted,
  NOTIFICATION.crmMeetingDeclined,
]

export const CHAT = {
  count: 40,
  asc: 'asc',
  desc: 'desc',
}

export const MODALS = {
  ACCOUNT_DELETE_MODAL: 'ACCOUNT_DELETE_MODAL',
  BLOCKED_DOMAIN_MODAL: 'BLOCKED_DOMAIN_MODAL',
  CHAT_ACCESS_REQUIRED_MODAL: 'CHAT_ACCESS_REQUIRED_MODAL',
  CODE_RESENT_MODAL: 'CODE_RESENT_MODAL',
  CONFIRM_ACCOUNT_DELETE_MODAL: 'CONFIRM_ACCOUNT_DELETE_MODAL',
  CONFIRM_LEAVE_OFFICE_MODAL: 'CONFIRM_LEAVE_OFFICE_MODAL',
  CONFIRM_ROUTE_CHANGE: 'CONFIRM_ROUTE_CHANGE',
  DELETE_PHOTO_MODAL: 'DELETE_PHOTO_MODAL',
  DELETED_ACCOUNT_MODAL: 'DELETED_ACCOUNT_MODAL',
  EDIT_PHOTO_MODAL: 'EDIT_PHOTO_MODAL',
  EXPERIENCE_RATING_MODAL: 'EXPERIENCE_RATING_MODAL',
  EXPIRED_LINK_MODAL: 'EXPIRED_LINK_MODAL',
  FORWARD_MESSAGE_MODAL: 'FORWARD_MESSAGE_MODAL',
  GENERIC_ERROR_MODAL: 'GENERIC_ERROR_MODAL',
  INVERTED_INVITE_MODAL: 'INVERTED_INVITE_MODAL',
  INVITE_BLOCKED_DOMAIN_ERROR_MODAL: 'INVITE_BLOCKED_DOMAIN_ERROR_MODAL',
  INVITE_SENT_MODAL: 'INVITE_SENT_MODAL',
  INVITE_SUCCESS_MODAL: 'INVITE_SUCCESS_MODAL',
  INVITE_TO_CONNECT_MODAL: 'INVITE_TO_CONNECT_MODAL',
  INVITE_YOURSELF_MODAL: 'INVITE_YOURSELF_MODAL',
  LEAVE_PRESENTATION_MODAL: 'LEAVE_PRESENTATION_MODAL',
  MEETING_ENDED_MODAL: 'MEETING_ENDED_MODAL',
  MESSAGED_YOU_MODAL: 'MESSAGED_YOU_MODAL',
  NO_CONNECTION_MODAL: 'NO_CONNECTION_MODAL',
  NOTES_MODAL: 'NOTES_MODAL',
  OFFICE_HOURS_EDITOR_MODAL: 'OFFICE_HOURS_EDITOR_MODAL',
  PRESENTATION_ENDED_MODAL: 'PRESENTATION_ENDED_MODAL',
  PRESENTATION_STARTING_MODAL: 'PRESENTATION_STARTING_MODAL',
  REINVITE_HCP_MODAL: 'REINVITE_HCP_MODAL',
  REMOVE_CONNECTION_MODAL: 'REMOVE_CONNECTION_MODAL',
  REQUEST_SAMPLES_MODAL: 'REQUEST_SAMPLES_MODAL',
  SEND_DBC_CONTACT: 'SEND_DBC_CONTACT',
  SEND_MESSAGE_MODAL: 'SEND_MESSAGE_MODAL',
  SESSION_EXPIRED_MODAL: 'SESSION_EXPIRED_MODAL',
  SESSION_EXPIRING_MODAL: 'SESSION_EXPIRING_MODAL',
  SHARE_CONTACT_MODAL: 'SHARE_CONTACT_MODAL',
  SHARE_DBC_MODAL: 'SHARE_DBC_MODAL',
  SHARE_BRAND_RESOURCE_MODAL: 'SHARE_BRAND_RESOURCE_MODAL',
  SIGN_IN_REQUIRED_MODAL: 'SIGN_IN_REQUIRED_MODAL',
  WELCOME_MODAL: 'WELCOME_MODAL',
}

export const LOCALSTORAGE = {
  accessToken: 'myveeva-accessToken',
  authStatus: 'myveeva-authStatus',
  clientSecret: 'myveeva-clientSecret',
  cookieConsent: 'myveeva-cookieConsent',
  countryCode: 'myveeva-countryCode',
  engageHost: 'myveeva-engageHost',
  email: 'myveeva-email',
  experience: 'myveeva-experience',
  invertedInviteDisplayName: 'myveeva-invertedInviteDisplayName',
  joinType: 'myveeva-joinType',
  isInitialSessionManual: 'myveeva-isInitialSessionManual',
  locale: 'myveeva-locale',
  loggingId: 'myveeva-loggingId',
  loginSource: 'myveeva-loginSource',
  meetingAccountId: 'myveeva-meetingAccountId',
  firstName: 'myveeva-firstName',
  fullName: 'myveeva-fullName',
  meetingId: 'myveeva-meetingId',
  lastName: 'myveeva-lastName',
  meetingPwd: 'myveeva-meetingPwd',
  refreshToken: 'myveeva-refreshToken',
  public: 'myveeva-public',
  sessionId: 'myveeva-sessionId',
  surveyData: 'myveeva-surveyData',
  user: 'myveeva-user',
  userRole: 'myveeva-userRole',
  welcome: 'myveeva-welcome',
}

export const CONTENT_FRAGMENT = {
  link: 'LINK',
  text: 'TEXT',
  thumbnailLink: 'THUMBNAIL_LINK',
}

export const ROLES = {
  hcp: 'HCP',
  rep: 'REP',
  unknown: 'UNKNOWN',
}

export const ROLES_TO_DOSSIER = {
  HCP: 'hcps',
  REP: 'reps',
}

export const CHANNEL_TYPE = {
  off: 'OFF',
  ping: 'PING',
  chat: 'CHAT',
}

export const ERROR = {
  blockedDomain: 'BLOCKED_DOMAIN',
  chatNotEnabled: 'CHAT_NOT_ENABLED',
  contactShareForbidden: 'CONTACT_SHARE_FORBIDDEN',
  crossCompany: 'CROSS_COMPANY',
  dataConsentRequired: 'DATA_CONSENT_REQUIRED',
  disconnected: 'DISCONNECTED',
  duplicate: 'DUPLICATE',
  invalid: 'INVALID',
  self: 'SELF',
  validation: 'VALIDATION',
  verificationInvalidated: 'VERIFICATION_INVALIDATED',
}

export const NEXT = {
  signup: 'SIGNUP',
  success: 'SUCCESS',
}

export const SURNAME_ORDER_COUNTRIES = ['JP', 'KR', 'TW']

export const I18N = {
  supportedLanguages: [
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'es-ES',
    'fi',
    'fr',
    'fr-CA',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'nl',
    'pl',
    'pt-BR',
    'ro',
    'ru',
    'sk',
    'sv',
    'th',
    'tr',
    'uk',
    'vi',
    'zh-CN',
    'zh-TW',
  ],
  defaultLanguage: 'en',
  namespaces: {
    web: 'web',
  },
}

export const LOGIN_SOURCE = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  POST_ENGAGE_MEETING: 'POST_ENGAGE_MEETING',
  POST_REMOTE_CLM: 'POST_REMOTE_CLM',
  POST_MEETING_REQUEST: 'POST_MEETING_REQUEST',
  PROFILE_SIGN_UP_TO_CONNECT: 'PROFILE_SIGN_UP_TO_CONNECT',
  PROFILE_BRAND_CARD: 'PROFILE_BRAND_CARD',
  PROFILE_CHAT: 'PROFILE_CHAT',
  PROFILE_PING: 'PROFILE_PING',
  PROFILE_SCHEDULE: 'PROFILE_SCHEDULE',
}

export const LOGGING = {
  NS: {
    WEB: 'web',
    SCHEDULING: 'scheduling',
  },
  SYSTEM: {
    EC: 'EC',
    CRM: 'CRM',
    VAULT: 'VAULT',
  },
  EVENT_LEVELS: {
    FATAL: 'FATAL',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    WARN: 'WARN',
    INFO: 'INFO',
    DEBUG: 'DEBUG',
  },
  EVENT_TYPES: {
    API: 'api',
    AUTH: 'auth',
    BRAND_PROFILE_ACTIVITY: 'events.brand_profile_activity',
    CHAT_LINK_CLICK: 'events.chat_link_click',
    CHAT: 'chat',
    ENGAGE_MEETING_JOIN: 'events.engage_meeting_join',
    ERROR: 'error',
    HCP_INITIATED: 'scheduling.hcp_initiated',
    HCP_INITIATED_SUBMIT: 'scheduling.hcp_initiated.submit',
    HCP_TO_REP_SUBMIT: 'scheduling.hcp_to_rep.submit',
    HCP_TO_REP: 'scheduling.hcp_to_rep',
    INVITE: 'invite',
    NAV: 'nav',
    NOTIFICATION: 'notification',
    REMOTE_CLM_JOIN: 'events.remote_clm_join',
    REP_TO_HCP_SUBMIT: 'scheduling.rep_to_hcp.submit',
    REP_TO_HCP: 'scheduling.rep_to_hcp',
    USER_PROFILE_ACTIVITY: 'events.user_profile_activity',
    WV_AUTH: 'webview.authenticated',
    WV_INIT: 'webview.init',
    WV_UNAUTH: 'webview.unauthenticated',
  },
  EVENT_MESSAGES: {
    NAVIGATED_TO: 'User navigated to: {0}',
    REDIRECTED_TO: 'User was redirected to: {0}',
    LOGIN: 'User login: {0}',
    LOGOUT: 'User logout: {0}',
    API_REQUEST: 'Rest API request made: {0} {1}',
    UNCAUGHT_ERROR: 'Uncaught Error: {0}',
    TOKEN_EXPIRED: 'Token expired',
    WV_INIT_TIMEOUT: 'Webview initialized. No actions taken after 5s.',
  },
  ACTION_CATEGORY: {
    BRAND_PROFILE: 'BRAND_PROFILE',
    COMPANY_BRANDS: 'COMPANY_BRANDS',
    CONTACTS: 'CONTACTS',
    HCP_RESOURCES: 'HCP_RESOURCES',
    OTHER: 'OTHER',
    REP_PROFILE: 'REP_PROFILE',
    SAMPLES_SELECTOR: 'SAMPLES_SELECTOR',
    SEARCH: 'SEARCH',
  },
  ACTION_DETAIL: {
    SHARED_CONTACT: 'SHARED_CONTACT',
    CHAT_THREAD_HEADER: 'CHAT_THREAD_HEADER',
    CHAT_THREAD_HEADER_MENU: 'CHAT_THREAD_HEADER_MENU',
    CHAT_THREAD_PHONE: 'CHAT_THREAD_PHONE',
    CHAT_THREAD_SCHEDULE: 'CHAT_THREAD_SCHEDULE',
    CHAT_FORWARDED_MESSAGE_AUTHOR: 'CHAT_FORWARDED_MESSAGE_AUTHOR',
    BRAND_PROFILE_CONTACTS: 'BRAND_PROFILE_CONTACTS',
    SEARCH_RESULTS: 'SEARCH_RESULTS',
    RECENT_SEARCHES: 'RECENT_SEARCHES',
    CONNECTIONS: 'CONNECTIONS',
    OFFICE_DETAIL: 'OFFICE_DETAIL',
    NOTIFICATION_CARD: 'NOTIFICATION_CARD',
    MEETING_DETAIL: 'MEETING_DETAIL',
    OTHER: 'OTHER',
  },
  ACTION_TYPE: {
    ADD_REP_NOTE: 'ADD_REP_NOTE',
    BRAND_PROFILE: 'BRAND_PROFILE',
    BRAND_RESOURCE_CHAT: 'BRAND_RESOURCE_CHAT',
    CHAT_THREAD_CONNECT: 'CHAT_THREAD_CONNECT',
    CHAT_THREAD_HEADER_MENU: 'CHAT_THREAD_HEADER_MENU',
    CLICK_BRAND_CARD: 'CLICK_BRAND_CARD',
    CLICK_CHAT: 'CLICK_CHAT',
    CLICK_CONNECT: 'CLICK_CONNECT',
    CLICK_CONNECTED: 'CLICK_CONNECTED',
    CLICK_EMAIL: 'CLICK_EMAIL',
    CLICK_MEETING: 'CLICK_MEETING',
    CLICK_OFFICE: 'CLICK_OFFICE',
    CLICK_PHONE: 'CLICK_PHONE',
    CLICK_PING: 'CLICK_PING',
    CLICK_REGULATORY_LINK: 'CLICK_REGULATORY_LINK',
    CLICK_REMOVE_CONNECTION_MENU: 'CLICK_REMOVE_CONNECTION_MENU',
    CLICK_REMOVE_CONNECTION: 'CLICK_REMOVE_CONNECTION',
    CLICK_SAVE_CONTACT: 'CLICK_SAVE_CONTACT',
    CLICK_SCHEDULE: 'CLICK_SCHEDULE',
    CLICK_SHARE_CONTACT: 'CLICK_SHARE_CONTACT',
    CLICK_SIGN_UP_TO_CONNECT: 'CLICK_SIGN_UP_TO_CONNECT',
    CONTACT_US: 'CONTACT_US',
    CONTACTS: 'CONTACTS',
    EDIT_REP_NOTE: 'EDIT_REP_NOTE',
    ENTER_EMAIL: 'ENTER_EMAIL',
    HCP: 'HCP_RESOURCES',
    HCP_PROFILE_CONNECT: 'HCP_PROFILE_CONNECT',
    MEDINFO: 'MEDICAL_INFORMATION',
    PATIENT_HUB: 'PATIENT_HUB',
    PATIENT: 'PATIENT_RESOURCES',
    REGULATORY_LINK: 'REGULATORY_LINK',
    REIMBURSEMENT: 'REIMBURSEMENT_RESOURCES',
    REP_PROFILE: 'REP_PROFILE',
    SHARE_DBC_CLICK_EMAIL: 'SHARE_DBC_CLICK_EMAIL',
    SHARE_DBC_COPY_LINK: 'SHARE_DBC_COPY_LINK',
    SHARE_DBC_SAVE_QR_CODE: 'SHARE_DBC_SAVE_QR_CODE',
    SHARE_DBC_SEND_CHAT: 'SHARE_DBC_SEND_CHAT',
    SHARE_DBC_SHARE_LINK: 'SHARE_DBC_SHARE_LINK',
    VIEW_PROFILE: 'VIEW_PROFILE',
    WEBSITE: 'WEBSITE',
  },
  JOIN_TYPE: {
    LINK: 'LINK',
    UPCOMING_MEETINGS_TAB: 'UPCOMING_MEETINGS_TAB',
    UPCOMING_MEETINGS_DETAILS: 'UPCOMING_MEETINGS_DETAILS',
    PREVIOUS_MEETINGS_DETAILS: 'PREVIOUS_MEETINGS_DETAILS',
    MANUAL: 'MANUAL',
    CHAT: 'CHAT',
    PUSH: 'PUSH',
    POPUP: 'POPUP',
    OTHER: 'OTHER',
  },
  CONTACT_SOURCE_TYPE: {
    REP_PROFILE_CONNECT: 'REP_PROFILE_CONNECT',
    REP_PROFILE_PHONE: 'REP_PROFILE_PHONE',
    REP_PROFILE_EMAIL: 'REP_PROFILE_EMAIL',
    REP_PROFILE_CHAT: 'REP_PROFILE_CHAT',
    REP_PROFILE_SCHEDULE: 'REP_PROFILE_SCHEDULE',
    OTHER: 'OTHER',
  },
  REFERENCE_TYPE: {
    BRAND_RESOURCE: 'brand_resource',
  },
}

export const CLM = {
  EVENTS: {
    INVALID_PIN: 'invalid-pin',
    MEETING_ENDED: 'meeting-ended',
    JOIN_SUCCESS: 'join-success',
    DISCONNECTED: 'disconnected',
    RECONNECTED: 'reconnected',
  },
  MESSAGE_HANDLERS: {
    INVALID_PIN: 'invalidPinNotifier',
    MEETING_ENDED: 'meetingEndedNotifier',
    JOIN_SUCCESS: 'joinSuccessNotifier',
    DISCONNECTED: 'disconnectNotifier',
    RECONNECTED: 'reconnectNotifier',
  },
}

export const ENGAGE = {
  SUPPORTED_REMOTE_SIGNATURES: 'SAMPLE,MEDICAL_INQUIRY,CONTENT_ACKNOWLEDGEMENT',
  WS_EVENT_TYPE: {
    MEDICAL_INQUIRY: 'MEDICAL_INQUIRY',
    SAMPLE: 'SAMPLE',
    AOC: 'CONTENT_ACKNOWLEDGEMENT'
  },
  WS_EVENT: {
    REQUEST_SIGNATURE: 'REQUEST_SIGNATURE',
    FINISH_SIGNATURE: 'FINISH_SIGNATURE',
    CANCEL_SIGNATURE: 'CANCEL_SIGNATURE',
  },
  MSG_EVENT: {
    CLOSE_SIGNATURE: 'closeSignature',
    CLOSE_SIGNATURE_PAGE: 'closeSignaturePage',
    POST_SIGNATURE: 'postSignature',
    FINISH_SIGNATURE: 'finishSignature',
  },
  POST_SIGNATURE_AUTO_CLOSE_TIMEOUT: 30000, // 30 seconds
  FINISH_SIGNATURE_AUTO_CLOSE_TIMEOUT: 5000, // 5 seconds,
  WAITING_ROOM_INTERVAL: 11000, // 11 seconds between each retry
  WAITING_ROOM_RETRY_TIMEOUT: 3600000, // 1 hour waiting room timeout
  QR_CODE_TIMEOUT: 30000, // 30 seconds QR code timeout
  JOIN_FAIL_RETRY_ENABLE: true, // control if we want the web client to auto retry when zoom return error code 1
  JOIN_FAIL_RETRY_INTERVAL: [5000, 10000, 20000], // how many times and how far apart between each retry

  NAME: {
    LANGUAGE: {
      DUTCH: 'nl-NL',
      ENGLISH: 'en-US',
      GERMAN: 'de-DE',
      SPANISH: 'es-ES',
      FRENCH: 'fr-FR',
      INDONESIAN: 'id-ID',
      ITALIAN: 'it-IT',
      JAPANESE: 'jp-JP',
      KOREAN: 'ko-KO',
      POLISH: 'pl-PL',
      PORTUGUESE: 'pt-PT',
      RUSSIAN: 'ru-RU',
      TURKISH: 'tr-TR',
      VIETNAMESE: 'vi-VN',
      CHINESE: 'zh-CN',
      TRADITIONAL_CHINESE: 'zh-TW',
    },
  },

  VEEVA_ADDL_SUPPORTED_LANGUAGES: [
    'bg',
    'cs',
    'da',
    'el',
    'fi',
    'fr-CA',
    'hu',
    'ro',
    'sk',
    'sv',
    'th',
    'uk',
  ],

  LOGGING: {
    EVENT_MESSAGES: {
      CLICK_JOIN: 'Join button clicked',
      INVOKE_ZOOM_SDK_JOIN: 'Invoking Zoom SDK join method',
      SHOW_QR: 'Zoom SDK did not take over UI, showing QR Code',
      WAITING_ROOM_RETRY_TIMEOUT: 'Waiting room retry timeout',
      REQUEST_SIGNATURE_SAMPLES:
        'HCP with attendeeId: {0} received REQUEST_SIGNATURE for samples on web socket',
      REQUEST_SIGNATURE_INQUIRIES:
        'HCP with attendeeId: {0} received REQUEST_SIGNATURE for inquiries on web socket',
      REQUEST_SIGNATURE_AOC:
        'HCP with attendeeId: {0} received REQUEST_SIGNATURE for aoc on web socket',
      CANCEL_SIGNATURE:
        'HCP with attendeeId: {0} received CANCEL_SIGNATURE on web socket',
      FINISH_SIGNATURE:
        'HCP with attendeeId: {0} received FINISH_SIGNATURE on web socket',
      SOCKET_CONNECT_SUCCESS:
        'HCP with attendeeId: {0} successfully connected to web socket',
      SOCKET_CONNECT_ERROR:
        'HCP with attendeeId: {0} failed to connect to web socket with error: {1} {2}',
      SOCKET_SUBSCRIBE_SUCCESS:
        'HCP with attendeeId: {0} successfully subscribed to web socket',
      SOCKET_SUBSCRIBE_ERROR:
        'HCP with attendeeId: {0} failed to connect to web socket with error: {1}',
      SOCKET_DISCONNECT_SUCCESS:
        'HCP with attendeeId: {0} successfully disconnected from web socket',
      SOCKET_DISCONNECT_ERROR:
        'HCP with attendeeId: {0} failed to disconnected from web socket gracefully with error: {1} {2}',
    },
    EVENT_TYPES: {
      SUCCESS_JOIN: 'success_join',
      ERROR_JOIN: 'error_join',
      ERROR_WEB_SOCKET: 'error_ws',
      REQUEST_SIGNATURE_SAMPLES: 'ws_request_signature_samples',
      REQUEST_SIGNATURE_INQUIRIES: 'ws_request_signature_inquiries',
      REQUEST_SIGNATURE_AOC: 'ws_request_signature_aoc',
      CANCEL_SIGNATURE: 'ws_cancel_signature',
      FINISH_SIGNATURE: 'ws_finish_signature',
      SOCKET_CONNECT: 'ws_connect',
      SOCKET_SUBSCRIBE: 'ws_subscribe',
      SOCKET_DISCONNECT: 'ws_disconnect',
      PARTICIPANT_JOIN_TRY: 'participant_join_try',
      PARTICIPANT_JOIN_WAIT: 'participant_join_wait',
      PARTICIPANT_JOIN_FAILURE: 'participant_join_failure',
      PARTICIPANT_JOIN_SUCCESS: 'participant_join_success',
      PARTICIPANT_LEAVE: 'participant_leave',
    },
  },
}
