import html2canvas from 'html2canvas'

export const exportQRCode = async (
  el: HTMLElement,
  fileName: string,
  title: string
) => {
  const canvas = await html2canvas(el, {
    onclone: function (clonedDoc) {
      const titleDiv = clonedDoc.createElement('titleDiv')
      titleDiv.setAttribute('style', 'margin-bottom: 8px;')
      titleDiv.innerHTML += title

      const qrCode = clonedDoc.getElementById(
        'share-dbc-modal--qr-code'
      ) as HTMLElement
      qrCode.prepend(titleDiv)
    },
  })

  const image = canvas.toDataURL('image/png', 1.0)
  const newLink = window.document.createElement('a')
  newLink.setAttribute('style', 'display:none;')
  newLink.download = fileName
  newLink.href = image
  document.body.appendChild(newLink)
  newLink.click()
  document.body.removeChild(newLink)
  newLink.remove()
}
