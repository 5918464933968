import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { ModalRootProps, SocketEventData } from '@web/_types'
import { ModalContext } from '../context/ModalContext'
import { AuthContext } from '@web/js/context/AuthContext'
import { SocketContext } from '../context/SocketContext'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from '@web/common/hooks'
import {
  LOCALSTORAGE,
  MODALS,
  SOCKET_EVENT,
  SOCKET_EVENT_TYPE,
} from '@web/_constants'
import { getQueryStringParams } from '@web/_utils'

//MODALS
import WelcomeModal from './WelcomeModal'
import RequestSamplesModal from './RequestSamplesModal'
import ReinviteHCPModal from './ReinviteHCPModal'
import RemoveConnectionModal from './RemoveConnectionModal'
import InviteSentModal from './InviteSentModal'
import InviteToConnectModal from './InviteToConnectModal'
import DeletedAccountModal from './DeletedAccountModal'
import ConfirmAccountDeleteModal from './ConfirmAccountDeleteModal'
import AccounDeletetModal from './AccounDeletetModal'
import ExperienceRatingModal from './ExperienceRatingModal'
import MeetingEndedModal from './MeetingEndedModal'
import PresentationEndedModal from './PresentationEndedModal'
import LeavingPresentationModal from './LeavingPresentationModal'
import NoConnectionModal from './NoConnectionModal'
import ConfirmLeaveOfficeModal from './ConfirmLeaveOfficeModal'
import InvertedInviteModal from './InvertedInviteModal'
import GenericErrorModal from './GenericErrorModal'
import NotesModal from './NotesModal'
import CodeResentModal from './CodeResentModal'
import BlockedDomainModal from './BlockedDomainModal'
import ChatAccessRequiredModal from './ChatAccessRequiredModal'
import OfficeHoursEditorModal from './OfficeHoursEditorModal'
import SignInRequiredModal from './SignInRequiredModal'
import SessionExpiringModal from './SessionExpiringModal'
import SessionExpiredModal from './SessionExpiredModal'
import ConfirmRouteChangeModal from './ConfirmRouteChangeModal'
import PresentationStartingModal from './PresentationStartingModal'
import InviteSuccessModal from './InviteSuccessModal'
import InviteYourselfModal from './InviteYourselfModal'
import InviteBlockedDomainErrorModal from './InviteBlockedDomainErrorModal'
import DeletePhotoModal from './DeletePhotoConfirm'
import EditPhotoModal from './EditPhotoModal'
import ShareContactModal from './ShareContactModal'
import ShareBrandResourceModal from './ShareBrandResourceModal'
import ForwardMessageModal from './ForwardMessageModal'
import ExpiredLinkModal from './ExpiredLinkModal'
import HCPMessagedYouModal from './HCPMessagedYouModal'
import SendMessageModal from './SendMessageModal'
import ShareDBCModal from './ShareDBCModal'
import SendDBCContactModal from './SendDBCContactModal'

const MODALS_MAP: { [name: string]: FunctionComponent } = {
  ACCOUNT_DELETE_MODAL: AccounDeletetModal,
  BLOCKED_DOMAIN_MODAL: BlockedDomainModal,
  CHAT_ACCESS_REQUIRED_MODAL: ChatAccessRequiredModal,
  CODE_RESENT_MODAL: CodeResentModal,
  CONFIRM_ACCOUNT_DELETE_MODAL: ConfirmAccountDeleteModal,
  CONFIRM_LEAVE_OFFICE_MODAL: ConfirmLeaveOfficeModal,
  CONFIRM_ROUTE_CHANGE: ConfirmRouteChangeModal,
  DELETE_PHOTO_MODAL: DeletePhotoModal,
  DELETED_ACCOUNT_MODAL: DeletedAccountModal,
  EDIT_PHOTO_MODAL: EditPhotoModal,
  EXPERIENCE_RATING_MODAL: ExperienceRatingModal,
  EXPIRED_LINK_MODAL: ExpiredLinkModal,
  FORWARD_MESSAGE_MODAL: ForwardMessageModal,
  GENERIC_ERROR_MODAL: GenericErrorModal,
  INVERTED_INVITE_MODAL: InvertedInviteModal,
  INVITE_BLOCKED_DOMAIN_ERROR_MODAL: InviteBlockedDomainErrorModal,
  INVITE_SENT_MODAL: InviteSentModal,
  INVITE_SUCCESS_MODAL: InviteSuccessModal,
  INVITE_TO_CONNECT_MODAL: InviteToConnectModal,
  INVITE_YOURSELF_MODAL: InviteYourselfModal,
  LEAVE_PRESENTATION_MODAL: LeavingPresentationModal,
  MEETING_ENDED_MODAL: MeetingEndedModal,
  MESSAGED_YOU_MODAL: HCPMessagedYouModal,
  NO_CONNECTION_MODAL: NoConnectionModal,
  NOTES_MODAL: NotesModal,
  OFFICE_HOURS_EDITOR_MODAL: OfficeHoursEditorModal,
  PRESENTATION_ENDED_MODAL: PresentationEndedModal,
  PRESENTATION_STARTING_MODAL: PresentationStartingModal,
  REINVITE_HCP_MODAL: ReinviteHCPModal,
  REMOVE_CONNECTION_MODAL: RemoveConnectionModal,
  REQUEST_SAMPLES_MODAL: RequestSamplesModal,
  SEND_DBC_CONTACT: SendDBCContactModal,
  SEND_MESSAGE_MODAL: SendMessageModal,
  SESSION_EXPIRED_MODAL: SessionExpiredModal,
  SESSION_EXPIRING_MODAL: SessionExpiringModal,
  SHARE_CONTACT_MODAL: ShareContactModal,
  SHARE_DBC_MODAL: ShareDBCModal,
  SHARE_BRAND_RESOURCE_MODAL: ShareBrandResourceModal,
  SIGN_IN_REQUIRED_MODAL: SignInRequiredModal,
  WELCOME_MODAL: WelcomeModal,
}

const ModalRoot = () => {
  const { search } = useLocation()
  const { modalProps, showModal } = useContext(ModalContext)
  const { showAccountDeleted, setShowAccountDeleted, isExpiring, hasExpired } =
    useContext(AuthContext)
  const { socket } = useContext(SocketContext)

  const [invertedInviteDisplayName] = useLocalStorage<string | null>(
    LOCALSTORAGE.invertedInviteDisplayName,
    null
  )

  const [experience] = useLocalStorage<string | null>(
    LOCALSTORAGE.experience,
    null
  )

  const socketEventListener = useCallback(
    (event: SocketEventData) => {
      if (SOCKET_EVENT_TYPE.remoteClmStarted === event.type) {
        showModal({
          name: MODALS.PRESENTATION_STARTING_MODAL,
          data: {
            eventData: event.data,
          },
        })
      }
    },
    [socket]
  )

  useEffect(() => {
    if (socket) socket.on(SOCKET_EVENT.message, socketEventListener)

    return () => {
      if (socket) socket.off(SOCKET_EVENT.message, socketEventListener)
    }
  }, [socketEventListener])

  useEffect(() => {
    const queryArgs = getQueryStringParams(window.location.search)

    if (queryArgs.experienceId && queryArgs.experienceId === experience)
      showModal({
        name: MODALS.EXPERIENCE_RATING_MODAL,
        data: {
          experienceId: queryArgs.experienceId,
        },
      })

    if (queryArgs.meetingEnded)
      showModal({
        name: MODALS.MEETING_ENDED_MODAL,
      })
  }, [search, experience])

  useEffect(() => {
    if (isExpiring)
      showModal({
        name: MODALS.SESSION_EXPIRING_MODAL,
      })
  }, [isExpiring])

  useEffect(() => {
    if (hasExpired)
      showModal({
        name: MODALS.SESSION_EXPIRED_MODAL,
      })
  }, [hasExpired])

  useEffect(() => {
    if (showAccountDeleted)
      showModal({
        name: MODALS.DELETED_ACCOUNT_MODAL,
        data: {
          setShowAccountDeleted,
        },
      })
  }, [showAccountDeleted])

  useEffect(() => {
    if (invertedInviteDisplayName)
      showModal({
        name: MODALS.INVERTED_INVITE_MODAL,
      })
  }, [invertedInviteDisplayName])

  if (!modalProps) return null
  const Modal = MODALS_MAP[(modalProps as ModalRootProps).name]
  return <Modal {...modalProps.data} />
}

export default ModalRoot
