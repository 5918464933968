import { I18N, LOGGING, MODALS } from '@web/_constants'
import React, { useContext, useRef, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import './ShareDBCModal.scss'
import Modal, { ModalBody } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'
import { Rep } from '@web/_types'
import Button from '@web/js/components/Button'
import { isIPad, isMobile } from '@admin/common/js/IsMobile'
import { AuthContext } from '@web/js/context/AuthContext'
import { exportQRCode } from '@web/_utils/exportQRCode'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'
import ChatIcon from '@web/common/img/SendChatIcon.svg'
import EmailIcon from '@web/common/img/EmailIconLarge.svg'
import CopyIcon from '@web/common/img/CopyLinkIcon.svg'
import DownloadIcon from '@web/common/img/DownloadIcon.svg'
import ShareIcon from '@web/common/img/ShareLinkIcon.svg'

interface ShareDBCModalProps {
  connection: Rep
}

const ShareDBCModal: React.FC<ShareDBCModalProps> = ({ connection }) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const dispatch = useDispatch()
  const { authenticated } = useContext(AuthContext)
  const { hideModal, showModal } = useContext(ModalContext)
  const [copied, setCopied] = useState(false)
  const qrRef = useRef<HTMLDivElement>(null)

  const logEvent = (actionType: string) => {
    const eventMessage = {
      profileId: connection.id,
      actionType,
    }

    dispatch(
      createLog(
        LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
        JSON.stringify(eventMessage)
      )
    )
  }

  const copyLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
    //prevent sticky focused state
    e.currentTarget.blur()

    await navigator.clipboard.writeText(connection.profileShareLink as string)
    setCopied(true)
    logEvent(LOGGING.ACTION_TYPE.SHARE_DBC_COPY_LINK)
  }

  const emailLink = () => {
    window.open(
      `mailto:?subject=${t('repsDBC', {
        name: connection.displayName,
      })}&body=${encodeURIComponent(connection.profileShareLink as string)}`
    )
    logEvent(LOGGING.ACTION_TYPE.SHARE_DBC_CLICK_EMAIL)
  }

  const shareLink = async () => {
    const shareData = {
      title: connection.displayName,
      text: t('repsDBC', { name: connection.displayName }),
      url: connection.profileShareLink,
    }

    navigator.share(shareData as ShareData)
    logEvent(LOGGING.ACTION_TYPE.SHARE_DBC_SHARE_LINK)
  }

  const sendChat = () => {
    showModal({
      name: MODALS.SEND_DBC_CONTACT,
      data: { rep: connection },
    })
    logEvent(LOGGING.ACTION_TYPE.SHARE_DBC_SEND_CHAT)
  }

  const saveQRCode = () => {
    exportQRCode(
      qrRef.current as HTMLDivElement,
      connection.displayName,
      t('repsDBC', { name: connection.displayName })
    )
    logEvent(LOGGING.ACTION_TYPE.SHARE_DBC_SAVE_QR_CODE)
  }

  return (
    <Modal size="lg" title={t('shareBusinessCard')} onClose={hideModal}>
      <ModalBody id="share-dbc-modal">
        <div id="share-dbc-modal--title" className="text-default-medium">
          {t('shareRepsBusinessCard', { name: connection.displayName })}
        </div>
        <div id="share-dbc-modal--actions">
          {authenticated ? (
            <Button 
            size="lg" 
            outline={true} 
            iconFront={ <img src={ChatIcon}/> }
            onClick={sendChat}>
              {t('sendChat')}
            </Button>
          ) : (
            <Button 
            size="lg" 
            outline={true} 
            iconFront={ <img src={CopyIcon}/> }
            onClick={copyLink}>
              {copied ? t('copied') : t('copyLink')}
            </Button>
          )}

          {(isMobile || isIPad) && !!navigator.share ? (
            <Button 
            size="lg" 
            outline={true}
            iconFront={<img src={ShareIcon}/> }
            onClick={shareLink}>
              {t('shareLink')}
            </Button>
          ) : (
            <Button 
            size="lg" 
            outline={true} 
            iconFront={ <img src={EmailIcon}/> }
            onClick={emailLink}>
              {t('emailLink')}
            </Button>
          )}
        </div>

        <div className="text-default-medium">{t('orShareViaQRCode')}</div>

        <div id="share-dbc-modal--qr-code" ref={qrRef}>
          <QRCodeCanvas
            value={connection.profileShareLink as string}
            size={224}
          />
        </div>

        <div id="share-dbc-modal--save-qr-code">
          <Button 
          size="lg" 
          outline={true} 
          iconFront={ <img src={DownloadIcon}/> }
          onClick={saveQRCode}>
            {t('saveQRCode')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ShareDBCModal
