import React, { useContext, useRef } from 'react'
import { ERROR, I18N, MODALS, ROUTES, SUPPORT_LINK } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import { ChatContext } from '@web/js/context/ChatContext'
import { Connection, ForwardMessage, RecipientEmail } from '@web/_types'
import { ChatService } from '@web/_services'
import _ from 'lodash'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import { ModalContext } from '@web/js/context/ModalContext'
import Modal, { ModalBody, ModalFooter } from '@web/js/components/Modal'
import RecipientSelector from '@web/js/components/RecipientSelector'
import OptionalMessagePreview from '@web/js/components/OptionalMessagePreview'

const ForwardMessageModal: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const chatRouteMatch = matchRoutes(
    [{ path: ROUTES.chat }, { path: ROUTES.chatChannel }],
    location
  )
  const { t } = useTranslation(I18N.namespaces.web)

  const { showModal, hideModal } = useContext(ModalContext)

  const isForwardingRef = useRef(false)
  const optionalMessageRef = useRef<HTMLTextAreaElement>(null)

  const {
    chatChannels,
    getChannels,
    setOpenMiniChatTo,
    forwardMessage,
    setForwardMessage,
  } = useContext(ChatContext)

  const resetForwardMessage = () => {
    setForwardMessage(null)
    hideModal()
  }

  const clearRecipientEmail = () => {
    setForwardMessage({
      ...forwardMessage,
      recipient: null,
      userId: null,
      email: null,
      emailStatus: null,
      originalAuthor: null,
      forwardedComment: null,
    } as ForwardMessage)
  }

  const setRecipient = (recipient: Connection | null) => {
    setForwardMessage({
      ...forwardMessage,
      recipient,
      userId: recipient?.id || null,
    } as ForwardMessage)
  }

  const setEmail = ({ email, emailStatus }: RecipientEmail) => {
    setForwardMessage({
      ...forwardMessage,
      email,
      emailStatus,
    } as ForwardMessage)
  }

  const routeToThread = (channelId: string) => {
    if (chatRouteMatch) {
      const route = `${ROUTES.chat}/${channelId}`
      navigate(route)
    } else {
      setOpenMiniChatTo(channelId)
    }
  }

  const handleAccept = async () => {
    if (!forwardMessage || isForwardingRef.current) return

    //using ref because setting state is too slow
    isForwardingRef.current = true

    const { userId, email, comment, message } = forwardMessage
    const forwardedComment = optionalMessageRef.current?.value
      ? optionalMessageRef.current?.value
      : null

    try {
      const forwardMessageResponse = await ChatService.forwardMessage({
        messageId: message.id,
        forwardedComment,
        userId,
        email,
        comment,
      })

      const channelId = forwardMessageResponse.data.channelId

      if (channelId) {
        const match = _.find(chatChannels, (c) => c.id === channelId)
        if (!match || !match.active) await getChannels()

        setTimeout(() => routeToThread(channelId), 0)
      }

      resetForwardMessage()
      isForwardingRef.current = false
    } catch (error) {
      let errorResponse = null
      let name = email

      if (error.response.data.code) errorResponse = error.response.data.code
      if (error.response.data.autoConnectedName)
        name = error.response.data.autoConnectedName

      //if error code exists without name/email present, trigger default error
      if (
        (!!errorResponse && !name) ||
        (errorResponse === ERROR.crossCompany &&
          !error.response.data.autoConnectedName)
      ) {
        errorResponse = null
      }

      let messageError
      switch (errorResponse) {
        case ERROR.chatNotEnabled:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorCannotReceiveChats', {
              name,
            }),
          }
          break
        case ERROR.crossCompany:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorPharmaContactsDiffCompany', {
              name,
            }),
          }
          break
        case ERROR.disconnected:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('errorMustBeConnected', {
              name,
            }),
          }
          break
        case ERROR.contactShareForbidden:
          messageError = {
            title: t('errorUnableToSendMessageTitle'),
            body: t('contactsCannotBeShared', {
              name,
            }),
          }
          break
        default:
          messageError = {
            title: t('errorSomethingWentWrongTitle'),
            body: t('errorNotAllowed', {
              support: SUPPORT_LINK,
            }),
          }
          break
      }

      if (messageError) {
        showModal({
          name: MODALS.GENERIC_ERROR_MODAL,
          data: {
            error: messageError,
          },
        })
      }

      isForwardingRef.current = false
    }
  }

  const loadUsers = (q: string, signal: AbortSignal) =>
    ChatService.getForwardMessageUsers(
      (forwardMessage as ForwardMessage).message.id,
      q.trim(),
      0,
      signal
    )

  return (
    <Modal size="lg" title={t('forwardMessage')} onClose={resetForwardMessage}>
      <ModalBody>
        {!!forwardMessage &&
          !forwardMessage.email &&
          !forwardMessage.userId && (
            <RecipientSelector
              loadData={loadUsers}
              setRecipient={setRecipient}
              setEmail={setEmail}
            />
          )}

        {!!forwardMessage &&
          (!!forwardMessage.email || !!forwardMessage.userId) && (
            <OptionalMessagePreview
              ref={optionalMessageRef}
              message={forwardMessage.message}
              recipient={forwardMessage.recipient}
              email={forwardMessage.email}
              emailStatus={forwardMessage.emailStatus}
              comment={!!forwardMessage.comment}
              clearRecipientData={clearRecipientEmail}
              forwarded={true}
            />
          )}
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onCancelText={t('cancel')}
        onAcceptText={t('send')}
        onAccept={handleAccept}
        onCancel={resetForwardMessage}
        disabled={!forwardMessage?.email && !forwardMessage?.userId}
      />
    </Modal>
  )
}
export default ForwardMessageModal
