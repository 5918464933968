import React, { useState, useContext, useEffect, useRef } from 'react'
import './ConnectionProfile.scss'
import {
  useLocation,
  useParams,
  useNavigate,
  matchRoutes,
} from 'react-router-dom'
import { ActionableDeeplink, Connection } from '@web/_types'
import { useTranslation } from 'react-i18next'
import { ROUTES, ROLES, I18N, LOGGING } from '@web/_constants'
import { ConnectionContext } from '@web/js/context/ConnectionContext'
import ConnectionDetails from '@web/js/components/ConnectionDetails'
import { UserService } from '@web/_services'
import { isHCP } from '@web/_guards'
import { getQueryStringParams, parseContacts } from '@web/_utils'
import MainLayoutReduced from '@web/js/layouts/MainLayoutReduced'
import { AuthContext } from '@web/js/context/AuthContext'
import { DeeplinkContext } from '@web/js/context/DeeplinkContext'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { createLog } from '@web/js/redux/logger/loggerActions'

interface RouterParams {
  id: string
}

const ConnectionProfile: React.FC = () => {
  const { id } = useParams<keyof RouterParams>() as RouterParams
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(I18N.namespaces.web)
  const location = useLocation()
  const queryArgs = getQueryStringParams(location.search)

  const { connections } = useContext(ConnectionContext)
  const { authenticated } = useContext(AuthContext)
  const { actionableDeeplink, getDeeplinkActions } = useContext(DeeplinkContext)

  const isHCPProfileRoute = !!matchRoutes([{ path: ROUTES.hcpId }], location)
  const isRepProfileRoute = !!matchRoutes([{ path: ROUTES.repId }], location)
  const isPublicDBCRoute = !!matchRoutes([{ path: ROUTES.dbc }], location)

  const isUnauthedRepProfile = !authenticated && isRepProfileRoute //determine if route to dbc
  const isPublicDBC = !authenticated && (isPublicDBCRoute || isRepProfileRoute)

  const connectionId = useRef<string>(id || queryArgs.repId)
  const actionableDeeplinkRef = useRef<ActionableDeeplink | null>(
    actionableDeeplink
  )

  const [notFound, setNotFound] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [connection, setConnection] = useState<Connection | undefined>(
    undefined
  )

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    actionableDeeplinkRef.current = actionableDeeplink
  }, [actionableDeeplink])

  useEffect(() => {
    //if connection route is changed via chat dock while on a profile
    if (!isLoading && !isPublicDBC) {
      connectionId.current = id || queryArgs.repId
      getProfile()
    }
  }, [location.state, connections])

  const init = async () => {
    if (isPublicDBC) {
      await getPublicDBC()
    } else {
      await getProfile()
    }
  }

  const logInvitedProfileView = (
    profileId: string,
    inviteLink?: string | null
  ) => {
    //vo = glass mode
    if (!!inviteLink && !queryArgs?.vo) {
      const eventMessage = {
        inviteLink,
        actionType: LOGGING.ACTION_TYPE.VIEW_PROFILE,
        profileId,
      }

      dispatch(
        createLog(
          LOGGING.EVENT_TYPES.USER_PROFILE_ACTIVITY,
          JSON.stringify(eventMessage)
        )
      )
    }
  }

  const getProfile = async () => {
    try {
      const inviteLink = _.clone(actionableDeeplinkRef.current?.deeplink)
      const type = isHCPProfileRoute
        ? ROLES.hcp.toLowerCase() + 's'
        : ROLES.rep.toLowerCase() + 's'
      const connection = await UserService.getUserByType(
        connectionId.current,
        type
      )
      setConnection(connection as Connection)
      setIsLoading(false)
      logInvitedProfileView(connection.id, inviteLink)
    } catch (error) {
      const errorResponse = error.response
      const errorData = errorResponse?.data

      if (
        errorResponse?.status === 403 &&
        errorData?.invertedInviteDisplayName
      ) {
        return
      }

      //retain HCP temporarily after disconnect - allow send invite
      if (connection) {
        connection.isContact = false
        setConnection(connection)
      }
      setNotFound(true)
      setIsLoading(false)
    }
  }

  const getPublicDBC = async () => {
    const location = isUnauthedRepProfile
      ? `${window.location.origin}/web${ROUTES.dbc}?repId=${connectionId.current}&i=1`
      : window.location.href

    try {
      if (!actionableDeeplinkRef.current) {
        actionableDeeplinkRef.current = (await getDeeplinkActions(
          location,
          false
        )) as ActionableDeeplink | null
      }

      const inviteLink = _.clone(actionableDeeplinkRef.current?.deeplink)
      const getPublicDBCResponse = await UserService.getPublicDBC(location, inviteLink)
      const connection = parseContacts(
        getPublicDBCResponse.data,
        true
      ) as Connection

      setConnection(connection)
      setIsLoading(false)
      logInvitedProfileView(connection.id, inviteLink)
    } catch (error) {
      navigate(ROUTES.login)
    }
  }

  const renderConnectionProfile = () => {
    return (
      <div id="connection-profile">
        {!connection && notFound ? (
          <div id="not-found" className="text-default-semibold">
            {t('userNotFound')}
          </div>
        ) : (
          <ConnectionDetails
            isPublicDBC={isPublicDBC}
            connection={connection}
            {...(connection &&
              isHCP(connection) && {
                createConnectionCallback: () => getProfile(),
              })}
          />
        )}
      </div>
    )
  }

  return (
    <>
      {isPublicDBC ? (
        <MainLayoutReduced>{renderConnectionProfile()}</MainLayoutReduced>
      ) : (
        renderConnectionProfile()
      )}
    </>
  )
}

export default ConnectionProfile
